import {NOTIFICATIONS_API} from '../services/notifications.http'
import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {useMe} from '@/features/authentication/queries/useMe'

export type GetNotificationsHistoryPropsType = {
    page?: string
    limit?: number
    appLang?: string
}

export const useGetNotificationsHistory = ({limit = 10, appLang}: GetNotificationsHistoryPropsType) => {
    const {data: user, loggedIn} = useMe()
    return useInfiniteQuery({
        queryKey: [QUERY_KEYS.notifications_history, appLang],
        queryFn: ({pageParam}) => NOTIFICATIONS_API.getNotificationsHistory({page: pageParam, limit}),
        initialPageParam: '',
        getNextPageParam: ({current_page, last_page}) =>
            current_page !== last_page ? `${current_page + 1}` : undefined,
        enabled: !!user && !!loggedIn
    })
}
