import {PaymentError, PaymentMethodUrls} from '@/features/guest-payments/types'
import {useRootStore} from '@/store'
import {selectSetPaymentMethodUrls} from '@/store/selectors'
import {useMutation} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {GUEST_API} from '../services/guest.http'
import {SubscriptionsUpdate} from '../types'
import {MUTATION_KEYS} from './keys'

export const useRenewSubscription = () => {
    const setPaymentMethodUrls = useRootStore(selectSetPaymentMethodUrls)
    return useMutation<AxiosResponse, PaymentError, SubscriptionsUpdate>({
        mutationKey: [MUTATION_KEYS.renew_subscription],
        mutationFn: GUEST_API.renewSubscription,
        onSettled: (_, error) => {
            if (error?.response?.status === 303) setPaymentMethodUrls(error?.response?.data as PaymentMethodUrls)
        }
    })
}
