import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {CHAT_API} from '../services/chat.http'
import {ChannelID, StoreChannelComplete} from '@/features/chat/types.ts'
import {useEffect} from 'react'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors'
import {useHandleError} from '@/hooks/useHandleError'

export const useGetChannelDetails = (channelID: ChannelID, enabled = true) => {
    const {setChannelDetails} = useRootStore(selectChatStore)
    const channel = useRootStore(store => store.chat.channels[channelID])
    const isGroupOrBroadcast = channelID?.includes('group') || channelID?.includes('broadcast')

    const query = useQuery({
        queryKey: [QUERY_KEYS.fetchChannelDetails, channelID],
        queryFn: () => CHAT_API.getChannelDetails(channelID),
        enabled: !!channelID && enabled && !isGroupOrBroadcast
    })

    useHandleError({isError: query?.isError, error: query?.error, isBlankPage: true})
    useEffect(() => {
        if (query.data) {
            setChannelDetails(channelID, query.data)
        }
    }, [query.data])

    return {
        ...query,
        data: query.isSuccess ? (channel as StoreChannelComplete) : null
    }
}
