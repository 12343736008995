import {useMe} from '@/features/authentication/queries/useMe.ts'
import {
    StyledChatIconWrapper,
    StyledFilledIcon,
    StyledHeaderItem,
    StyledIcon,
    StyledNotificationBox,
    StyledNotificationsInfo,
    popoverStyles
} from './style.ts'
import {Chat, ChatFill, NotificationsIconEmpty, NotificationsIconFill} from '@/assets/icons/icons'
import {ROUTE_MESSAGE_CENTER, ROUTE_WELCOME_BACK_PAGE} from '@/utilities/constants/routeNames'
import {USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user.ts'
import {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {RouteVisibilityFragment} from '@/components/ui/routeVisibilityFragment/RouteVisibilityFragment.tsx'
import useChat from '@/features/chat/hooks/useChat.ts'
import {Notifications} from '@/features/notifications/components/Notifications.tsx'
import {Popover} from '@/components/ui/popover/Popover.tsx'
import {useGetNotificationUnreadCount} from '@/features/notifications/queries/useGetNotificationUnreadCount.ts'
import {useTheme} from 'styled-components'
import useWindowDimensions from '@/hooks/useWindowDimensions.ts'
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll.ts'

interface HeaderActionItemsProps {
    isLight?: boolean
}

export const HeaderActionItems = ({isLight = true}: HeaderActionItemsProps) => {
    const theme = useTheme()
    const {data: user} = useMe()
    const navigate = useNavigate()
    const location = useLocation()
    const {height: vh} = useWindowDimensions()
    const [showNotifications, setShowNotifications] = useState(false)
    const {data: lastReadTimeToken} = useGetNotificationUnreadCount()
    const newNotifications = lastReadTimeToken?.count && lastReadTimeToken?.count > 0
    useLockBodyScroll({enable: showNotifications})
    const {conversations} = useChat()

    const unreadMessageCountSum = Object.values(conversations).reduce(
        (accumulator, item) => (accumulator += item?.unreadCounter ?? 0),
        0
    )

    const closeNotification = () => {
        setShowNotifications(false)
    }
    return (
        <RouteVisibilityFragment forbiddenRoutes={[ROUTE_WELCOME_BACK_PAGE]}>
            <StyledHeaderItem>
                <StyledChatIconWrapper
                    onClick={() => navigate(ROUTE_MESSAGE_CENTER)}
                    unreadMessageCountSum={unreadMessageCountSum}
                >
                    <span>{unreadMessageCountSum}</span>
                    {!location?.pathname.includes(ROUTE_MESSAGE_CENTER) ? (
                        <StyledIcon isLight={isLight}>
                            <Chat />
                        </StyledIcon>
                    ) : (
                       <StyledFilledIcon><ChatFill /></StyledFilledIcon> 
                    )}
                </StyledChatIconWrapper>

                {user?.type !== USER_ROLE_REPRESENTATIVE && (
                    <Popover
                        popoverStyles={() => popoverStyles({theme, vh})}
                        contentProps={{align: 'end'}}
                        isModal
                        trigger={
                            !showNotifications ? (
                                <StyledIcon isLight={isLight}>
                                    <StyledNotificationsInfo newNotifications={!!newNotifications} />
                                    <NotificationsIconEmpty />
                                </StyledIcon>
                            ) : (
                                <StyledNotificationBox>
                                    <NotificationsIconFill />
                                </StyledNotificationBox>
                            )
                        }
                        rootProps={{
                            open: showNotifications,
                            onOpenChange: () => setShowNotifications(!showNotifications)
                        }}
                    >
                        <Notifications closeNotification={closeNotification} />
                    </Popover>
                )}
            </StyledHeaderItem>
        </RouteVisibilityFragment>
    )
}
