import {z} from 'zod'

export const enum BILLING_INFO_MODEL {
    CostumerName = 'customer_name',
    Email = 'email',
    Phone = 'phone_number',
    Street = 'street',
    Address = 'address',
    City = 'city',
    ZipCode = 'zip_code',
    RowAddress = 'raw_address',
    CountryName = 'country_name',
    CountryCode = 'country_code',
    StateCode = 'state_code'
}
export const AddressSchema = z.object({
    [BILLING_INFO_MODEL.Address]: z.string().min(1, {
        message: 'validation:required_street'
    }),
    [BILLING_INFO_MODEL.City]: z.string().min(1, {
        message: 'validation:required_city'
    }),
    [BILLING_INFO_MODEL.ZipCode]: z.string().optional(),
    [BILLING_INFO_MODEL.RowAddress]: z.string().optional(),
    [BILLING_INFO_MODEL.CountryName]: z.string().optional(),
    [BILLING_INFO_MODEL.CountryCode]: z.string().optional(),
    [BILLING_INFO_MODEL.StateCode]: z.string().optional()
})

export const SendBillingInfoPayloadSchema = z.object({
    [BILLING_INFO_MODEL.CostumerName]: z.string(),
    [BILLING_INFO_MODEL.Email]: z.string(),
    [BILLING_INFO_MODEL.Phone]: z.string(),
    [BILLING_INFO_MODEL.Street]: AddressSchema.optional()
})

export const SendBillingValidationSchema = z.object({
    [BILLING_INFO_MODEL.CostumerName]: z
        .string()
        .min(1, {message: 'validation:required_customer_name'})
        .refine(val => /\b\w+\b.*\b\w+\b/.test(val), {
            message: 'validation:required_first_and_last_name' // first and last name required
        }),
    [BILLING_INFO_MODEL.Email]: z.string().min(1, {message: 'validation:required_email'}).email({
        message: 'validation:invalid_email'
    }),
    [BILLING_INFO_MODEL.Phone]: z
        .string()
        .min(1, {message: 'validation:required_phone'})
        .regex(/^[0-9]+$/, 'validation:only_digits')
        .min(7, 'validation:phone_number_short')
        .max(15, 'validation:phone_number_long'),
    [BILLING_INFO_MODEL.Street]: AddressSchema.optional().refine(data => data, {
        message: 'validation:required_address'
    })
})

export const BillingInfoResponseSchema = z.object({
    id: z.number(),
    first_name: z.string(),
    last_name: z.string(),
    user_id: z.number(),
    [BILLING_INFO_MODEL.Email]: z.string(),
    [BILLING_INFO_MODEL.Phone]: z.string(),
    [BILLING_INFO_MODEL.Address]: z.string(),
    [BILLING_INFO_MODEL.City]: z.string(),
    [BILLING_INFO_MODEL.ZipCode]: z.string(),
    [BILLING_INFO_MODEL.RowAddress]: z.string(),
    [BILLING_INFO_MODEL.CountryName]: z.string().optional(),
    [BILLING_INFO_MODEL.CountryCode]: z.string().optional(),
    [BILLING_INFO_MODEL.StateCode]: z.string().optional()
})
