import {useTranslation} from 'react-i18next'
import {StyledButton, StyledEmptySection, StyledInfoCircle, StyledListWrapper, StyledText, StyledWrapper} from './style'
import {HostType} from '@/features/authentication/types'
import {HostProfileSection} from '../host-profile-section/HostProfileSection'
import {selectOpenSurprisesModal} from '@/store/selectors'
import {useRootStore} from '@/store'
import {useEffect, useState} from 'react'
import {useGetHostSurprises} from '@/features/my-surprises/queries/useGetHostSurprises'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useHandleError} from '@/hooks/useHandleError'
import {HostSurprisePreview} from '@/features/my-surprises/components/host-surprise-preview/HostSurprisePreview'
import {getRandInt} from '@/utilities/helpers'
import {SurpriseTooltip} from '@/features/my-surprises/components/surprise-tooltip/SurpriseTooltip'
import {blurredPhotos} from '../../helpers/helpers'

export const HostProfileSurprisesSection = ({hostData}: {hostData: HostType}) => {
    const {t} = useTranslation()
    const {openModal} = useRootStore(selectOpenSurprisesModal)
    const limit = 4
    const {data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage} = useGetHostSurprises({
        limit,
        hostId: hostData?.id
    })
    const [randomIndexArray, setRandomIndexArray] = useState<number[]>([])

    const remappedData = data?.pages.flatMap(page => page.data) ?? []
    useEffect(() => {
        if (remappedData.length > randomIndexArray.length) {
            const newRandomIndexes: number[] = []
            for (let i = randomIndexArray.length; i < remappedData.length; i++) {
                newRandomIndexes.push(getRandInt(0, blurredPhotos.length))
            }
            setRandomIndexArray(prevArray => [...prevArray, ...newRandomIndexes])
        }
    }, [data?.pages?.length])

    useHandleError({isError, error})

    const leftItemToDownload = data
        ? data?.pages[data?.pages?.length - 1].total - Number(data?.pages[data?.pages?.length - 1]?.to)
        : 0
    return (
        <HostProfileSection
            title={t('rookie_my_profile:host_surprises', {HOST_NAME: hostData?.type_attributes?.first_name})}
            infoIcon={
                <SurpriseTooltip
                    trigger={<StyledInfoCircle />}
                    title={'rookie_profile_preview:host_surprises:what_is_surprise_title'}
                    text={'rookie_profile_preview:host_surprises:what_is_surprise_text'}
                />
            }
        >
            <StyledWrapper>
                <StyledText>
                    <SurpriseTooltip
                        trigger={<p>{t('rookie_profile_preview:host_surprises:what_get_title')}</p>}
                        title={'rookie_profile_preview:host_surprises:what_get_title'}
                        text={'rookie_profile_preview:host_surprises:what_get_text'}
                    />
                </StyledText>
                {isLoading ? (
                    <Spinner />
                ) : remappedData?.length ? (
                    <>
                        <StyledListWrapper>
                            {remappedData?.map((surprise, index) => (
                                <HostSurprisePreview
                                    key={surprise?.id}
                                    surprise={{
                                        ...surprise,
                                        blurred_url: surprise?.url ? null : blurredPhotos[randomIndexArray[index]]
                                    }}
                                    handleOpenSurprise={() =>
                                        openModal({
                                            hostId: hostData?.id,
                                            hostUserName: hostData?.username,
                                            surpriseId: surprise?.id
                                        })
                                    }
                                />
                            ))}
                        </StyledListWrapper>

                        <StyledButton
                            onClick={() => openModal({hostId: hostData?.id, hostUserName: hostData?.username})}
                        >
                            {t('rookie_profile_preview:host_surprises:random_button_text')}
                        </StyledButton>

                        {hasNextPage &&
                            (isFetchingNextPage ? (
                                <Spinner inline size={10} />
                            ) : (
                                <StyledText onClick={() => fetchNextPage()}>
                                    {t('rookie_profile_preview:host_surprises:view_surprises_button', {
                                        SURPRISES_SHOW: leftItemToDownload > limit ? limit : leftItemToDownload
                                    })}
                                </StyledText>
                            ))}
                    </>
                ) : (
                    <StyledEmptySection>
                        {t('rookie_my_profile:has_no_surprises', {USER: hostData?.type_attributes?.first_name})}
                    </StyledEmptySection>
                )}
            </StyledWrapper>
        </HostProfileSection>
    )
}

HostProfileSurprisesSection.displayName = 'HostProfileSurprisesSection'
