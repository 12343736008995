import {FC, Fragment, useState} from 'react'
import {PaymentPlatformSectionRoot, StyledShowMore} from './style'
import {SocialPlatformTypeE} from '../../types'
import PaymentPlatformCard from '../payment-platform-card/PaymentPlatformCard'
import {useTranslation} from 'react-i18next'
import {chunk} from 'lodash'

type PaymentPlatformSectionProps = {
    APMlinks: {
        name: SocialPlatformTypeE
        url: string
        icon: string
    }[]
}

const PaymentPlatformSection: FC<PaymentPlatformSectionProps> = ({APMlinks}) => {
    const {t} = useTranslation()
    const [isShowFullList, setIsShowFullList] = useState(false)
    const DEFAULT_ITEMS_TO_SHOW = 2

    return (
        <>
            <PaymentPlatformSectionRoot>
                {(isShowFullList ? APMlinks : chunk(APMlinks, DEFAULT_ITEMS_TO_SHOW)[0])?.map((item, index) => {
                    return (
                        <Fragment key={`${item.name} + ${index}`}>
                            <PaymentPlatformCard icon={item.icon} url={item.url} />
                        </Fragment>
                    )
                })}
            </PaymentPlatformSectionRoot>
            {APMlinks.length > 2 && (
                <StyledShowMore
                    onClick={() => {
                        setIsShowFullList(prev => !prev)
                    }}
                >
                    {t(`gift_rookie:${isShowFullList ? 'show_less_payment_methods' : 'show_more_payment_methods'}`)}
                </StyledShowMore>
            )}
        </>
    )
}

export default PaymentPlatformSection
