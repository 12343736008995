import {generateChatChannelName, getCorrectStringWithTranslation} from '@/utilities/helpers'
import {StyledActionBox, StyledButton, StyledDescription, StyledNotification} from './style'
import {HOST_PROFILE_CTA_TYPES} from '../templates/host-profile-template/HostProfileTemplate'
import {PACKAGE_PROMO_CTA_TYPES} from '../templates/package-promo-template/PackagePromoTemplate'
import {GOAL_TEMPLATE_CTA_TYPES} from '../templates/goal-promo-template/GoalPromoTemplate'
import {useGetFreeMorgis} from '../../queries/useGetFreeMorgis'
import {useHandleError} from '@/hooks/useHandleError'
import {PROMO_TYPES} from '../combinations'
import {useRootStore} from '@/store'
import {selectModals} from '@/store/selectors'
import useRedirectToChatChannel from '@/hooks/useRedirectToChatChannel'
import {generatePath, useNavigate} from 'react-router-dom'
import {ROUTE_GOAL} from '@/utilities/constants/routeNames'
import useChat from '@/features/chat/hooks/useChat'
import {useMe} from '@/features/authentication/queries/useMe'
import {useMoengageCombinationUI} from '@/integrations/moengage/MoengageProvider'
import {LocalStorageManager} from '@/utilities/localStorage'
import {TABS_VALUE} from '@/features/gift/components/gift-panel/GiftPanel'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '../../queries/keys'
import {useBuyMicromorgiPackage} from '@/features/gift/queries/useBuyMicromorgiPackage'
import {useEffect} from 'react'

const CTA = ({
    ctaText,
    isNotification = false,
    notificationMessage,
    backgroundColor,
    small,
    ctaType,
    promo,
    campaignId,
    hostId,
    packageData,
    goalSlug
}: {
    ctaText?: string
    isNotification?: boolean
    notificationMessage?: string
    backgroundColor?: string
    small?: boolean
    ctaType?: string
    promo?: {
        type: string
        amount: number
    }
    hostId?: number
    goalSlug?: string
    campaignId: number
    packageData?: {
        id: number
        micromorgi_count?: number
        price?: number
        level_status?: string
        is_visible?: boolean
        promo: {
            type?: string
            percent?: number
        }
    }
}) => {
    const isTranslationEnabled = false

    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const {closeHandler} = useMoengageCombinationUI()
    const {data: user} = useMe()
    const {channels} = useChat()
    const {mutate: getMicroMorgis, isError: isGetMicroMorgiError, error: getMicroMorgiError} = useGetFreeMorgis()
    const {connectNowModal, topFanPendingModal, recurringGiftModal, choosePaymentsMethodModal} =
        useRootStore(selectModals)
    const {mutate: buyMicromorgiPackage, error: buyMicromorgiPackageError, isPending} = useBuyMicromorgiPackage()

    useEffect(() => {
        if (packageData && buyMicromorgiPackageError && buyMicromorgiPackageError?.response) {
            const {
                response: {data, status}
            } = buyMicromorgiPackageError

            if (status === 303)
                choosePaymentsMethodModal.openModal({
                    paymentMethodUrls: data,
                    description: 'choose_payment_method:description_micromorgi',
                    morgisAmount: packageData.micromorgi_count,
                    currencyAmount: packageData.price,
                    apiCallback: (onlyLinks?: boolean, withOnlyLink?: boolean) =>
                        buyMicromorgiPackage({
                            packageId: `${packageData.id}`,
                            campaignId: `${campaignId}`,
                            ...(withOnlyLink && {only_links: onlyLinks})
                        })
                })
        }
    }, [packageData, buyMicromorgiPackageError])

    const redirectToChatChannel = useRedirectToChatChannel({receiverId: parseInt(`${hostId}`)})

    const isError = isGetMicroMorgiError
    const error = getMicroMorgiError
    useHandleError({isError, error})

    const handleGetFreeMicroMorgis = () => {
        if (promo?.type === PROMO_TYPES.free_morgis) {
            getMicroMorgis({campaign_id: campaignId})
        } else return
    }

    const channel = !!user && channels[generateChatChannelName(user.id, String(user?.type), parseInt(`${hostId}`))]
    const handleRedirectOrOpenConnection = () => {
        if (channel) {
            redirectToChatChannel?.()
        } else {
            !!hostId && connectNowModal.openModal({hostID: +hostId})
        }
    }

    //TODO add correct actions
    const actionHandle = {
        [HOST_PROFILE_CTA_TYPES.connect_now]: () => {
            handleRedirectOrOpenConnection()
            handleGetFreeMicroMorgis()
            closeHandler?.()
        },
        [HOST_PROFILE_CTA_TYPES.send_message]: () => {
            handleRedirectOrOpenConnection()
            handleGetFreeMicroMorgis()
            closeHandler?.()
        },
        [HOST_PROFILE_CTA_TYPES.became_top_fan]: () => {
            if (channel) {
                topFanPendingModal.openModal({channel})
            } else {
                handleRedirectOrOpenConnection()
            }
            handleGetFreeMicroMorgis()
            closeHandler?.()
        },
        [HOST_PROFILE_CTA_TYPES.send_gift]: () => {
            if (channel) {
                LocalStorageManager.giftPanelTab.set({channel: `${channel}`, tab: TABS_VALUE.gift})
                redirectToChatChannel?.()
            } else {
                handleRedirectOrOpenConnection()
            }
            handleGetFreeMicroMorgis()
            closeHandler?.()
        },
        [HOST_PROFILE_CTA_TYPES.send_tip]: () => {
            if (channel) {
                LocalStorageManager.giftPanelTab.set({channel: `${channel}`, tab: TABS_VALUE.morgi})
                redirectToChatChannel?.()
            } else {
                handleRedirectOrOpenConnection()
            }
            handleGetFreeMicroMorgis()
            closeHandler?.()
        },
        [HOST_PROFILE_CTA_TYPES.support_monthly]: () => {
            recurringGiftModal.openModal({hostID: `${hostId}` || null})
            handleGetFreeMicroMorgis()
            closeHandler?.()
        },
        [PACKAGE_PROMO_CTA_TYPES.go_to_offer]: () => {
            if (promo?.type !== PROMO_TYPES.free_morgis && packageData && !isPending) {
                console.log('MORGIS')
                buyMicromorgiPackage({
                    packageId: `${packageData.id}`,
                    campaignId: `${campaignId}`
                })
            } else {
                handleGetFreeMicroMorgis()
            }
            closeHandler?.()
        },
        [GOAL_TEMPLATE_CTA_TYPES.check_goal]: () => {
            if (goalSlug) {
                navigate(generatePath(ROUTE_GOAL, {slug: goalSlug}))
                handleGetFreeMicroMorgis()
            }
            closeHandler?.()
        }
    }

    if (!ctaType) return
    if (isNotification) {
        return (
            <StyledNotification
                onClick={() => {
                    actionHandle[ctaType]()
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.moengage_campaign]})
                }}
            >
                {!!notificationMessage && (
                    <StyledDescription>
                        {getCorrectStringWithTranslation(notificationMessage, isTranslationEnabled)}
                    </StyledDescription>
                )}
                <StyledActionBox></StyledActionBox>
            </StyledNotification>
        )
    } else {
        return ctaText ? (
            <StyledButton
                onClick={() => {
                    actionHandle[ctaType]()
                    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.moengage_campaign]})
                }}
                backgroundColor={backgroundColor}
                small={small}
            >
                {getCorrectStringWithTranslation(ctaText, isTranslationEnabled)}
            </StyledButton>
        ) : (
            <></>
        )
    }
}

export default CTA
