import {QUERY_KEYS as AUTH_QUERY_KEYS} from '@/features/authentication/queries/keys'
import {QUERY_KEYS as CHAT_QUERY_KEYS} from '@/features/chat/queries/keys'
import {MonthlySupportError, PaymentMethodUrls} from '@/features/guest-payments/types'
import {ROUTE_MESSAGE_CENTER} from '@/utilities/constants/routeNames'
import {LocalStorageManager} from '@/utilities/localStorage'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {RECURRING_GIFT_API} from '../services/recurringGift.http'
import {SendGiftRequest} from '../types'
import {MUTATION_KEYS, QUERY_KEYS} from './keys'
import {QUERY_KEYS as GUEST_QUERY_KEYS} from '@/features/guest/queries/keys'
import {selectSetPaymentMethodUrls} from '@/store/selectors'
import {useRootStore} from '@/store'

export const useSendGift = () => {
    const queryClient = useQueryClient()
    const setPaymentMethodUrls = useRootStore(selectSetPaymentMethodUrls)
    return useMutation<AxiosResponse, MonthlySupportError, SendGiftRequest>({
        mutationKey: [MUTATION_KEYS.send_gift],
        mutationFn: RECURRING_GIFT_API.sendGift,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [CHAT_QUERY_KEYS.fetchChannelDetails]})
            queryClient.invalidateQueries({queryKey: [CHAT_QUERY_KEYS.fetchChannels]})
            queryClient.invalidateQueries({queryKey: [AUTH_QUERY_KEYS.user]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.subscribed_hosts]})
            queryClient.invalidateQueries({queryKey: [GUEST_QUERY_KEYS.host]})
        },
        onSettled: (_, error) => {
            if (error?.response?.status === 303) setPaymentMethodUrls(error?.response?.data.url as PaymentMethodUrls)
            LocalStorageManager.paymentStartLocation.set(location.pathname ?? ROUTE_MESSAGE_CENTER)
        }
    })
}
