import {Button} from '@/components/ui/button/Button'
import styled, {DefaultTheme, css} from 'styled-components'

export const modalStyles = (theme: DefaultTheme) => css`
    max-height: 100vh;
    width: 100vw;
    ${theme.mediaQueries.m} {
        height: 100%;
    }
`

export const StyledModalTitle = styled.div`
    font-size: 22px;
    text-align: center;
    max-width: 280px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const StyledContentTitle = styled.h4`
    ${({theme: {palette, typography}}) => css`
        ${typography.textLg}
        text-align: center;
        font-weight: 400;
        letter-spacing: -0.05em;
        justify-self: center;
        color: ${palette.primary[900]};
    `}
`

export const StyledAmountsList = styled.div`
    ${({theme: {spacing}}) => css`
        overflow-y: auto;
        padding: 0 ${spacing}px;
        height: 100%;
        scrollbar-width: thin;
        & > :nth-last-child(1) {
            border-bottom: none;
        }
    `}
`
export const StyledFooter = styled.div`
    display: grid;
    justify-items: center;
`
export const StyledAmountListToggle = styled.span`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textLg}
        cursor: pointer;
        text-decoration: underline;
        color: ${palette.primary[900]};
        display: flex;
        justify-content: center;
        margin-top: auto;
        padding-bottom: ${spacing * 2}px;
    `}
`
export const StyledNextPageButton = styled(Button)`
    ${({theme: {typography}}) => css`
        ${typography.textMd}
        width: 210px;
        align-self: center;
        margin: 0 auto;
    `}
`
export const StyledGiftModalStepContainer = styled.div`
    ${({theme: {spacing, sizing}}) => css`
        display: grid;
        grid-template-rows: auto 1fr auto;
        padding: 0px ${spacing}px ${spacing}px;
        gap: ${spacing * 1.25}px;
        height: calc(100% - ${sizing.headerMobileHeight});
    `}
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        padding: 0 ${spacing}px ${spacing}px;
        gap: ${spacing * 2}px;
    `}
`

export const StyledRedirectToChatButton = styled(Button)`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textMd}
        padding: ${spacing * 2}px ${spacing * 3}px;
        font-weight: 600;
    `}
`

export const StyledShareButton = styled(Button)`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textMd}
        padding: ${spacing * 2}px ${spacing * 3}px;
        border-width: 1px;
        font-weight: 600;
    `}
`
export const StyledDeclaimer = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        ${typography.textXs}
        color: ${palette.primary[900]};
        margin: ${spacing}px 0 0 0;
        opacity: 0.5;
        text-align: center;
        & span {
            text-decoration-line: underline;
            cursor: pointer;
        }
    `}
`

export const StyledBannerWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 2}px;
    `}
`

export const StyledAnimation = styled.div`
    margin: 0 auto;
    width: 200px;
    height: 180px;
`

export const StyledSuccessTitle = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textLg};
        text-align: center;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: -0.05em;
    `}
`

export const StyledSuccessSubTitle = styled.span`
    ${({theme: {spacing, palette}}) => css`
        color: ${palette.neutral['25']};
        text-align: center;
        font-weight: 400;
        line-height: 20px;
        white-space: pre-wrap;
        margin-bottom: ${spacing}px;
    `}
`

export const StyledSuccessBox = styled.div`
    text-align: center;
    overflow-y: auto;
    scrollbar-width: thin;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const StyledSuccessTitleBox = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledSuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
`

export const StyledSuccessButtonBox = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.25}px;
        padding-top: ${spacing}px;
    `}
`
