import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Label} from '@/components/ui/label/Label'
import {FC} from 'react'
import {Controller, FieldValues, RegisterOptions, useFormContext} from 'react-hook-form'
import AutoCompleteAddress from '../auto-complete-address/AutoCompleteAddress'

type AutoCompleteAddressControllerProps = {
    name: string
    innerFields?: string[]
    label?: string
    helpText?: string
    rules?: // Rules for validation using react-hook-form
    Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'> | undefined
}

export const AutoCompleteAddressController: FC<AutoCompleteAddressControllerProps> = ({
    name,
    label,
    helpText,
    rules,
    innerFields
}) => {
    const {
        control,
        formState: {errors, defaultValues},
        trigger,
        clearErrors
    } = useFormContext()

    const error = errors[name]?.message
        ? errors[name]
        : errors[name] && Object.values(errors[name] as {[key: string]: {message: string}})?.[0]
    const keyOfInnerError = innerFields?.find(fieldName => errors[fieldName]) as string
    const innerError = errors[keyOfInnerError]
    const defaultValue = defaultValues?.[name]
    return (
        <>
            {label && <Label htmlFor={name} text={label} />}
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({field}) => (
                    <AutoCompleteAddress
                        name={name}
                        defaultValue={defaultValue}
                        error={(error?.message as string) || (innerError?.message as string)}
                        onChange={address => {
                            if (address === field.value) return
                            field.onChange(address)
                            clearErrors(innerFields)
                            trigger(name)
                        }}
                        onBlur={() => {
                            trigger(name)
                        }}
                    />
                )}
            />
            {helpText && <InputHelpText helpText={helpText} />}
        </>
    )
}
