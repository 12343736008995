import {Modal} from '@/components/ui/modal/Modal'
import {useTranslation} from 'react-i18next'
import BillingInfoForm from '../billing-info-form/BillingInfoForm'
import {StyledBody, StyledDescription} from './style'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {useGetBillingInfo} from '../../queries/useGetBillingInfo'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useHandleError} from '@/hooks/useHandleError'

type BillingInfoModalProps = {
    onClose: () => void
    onSendBillingInfoSuccess?: () => void
}

export const BillingInfoModal = ({onClose, onSendBillingInfoSuccess}: BillingInfoModalProps) => {
    const {t} = useTranslation()
    const {data} = useMe()
    const user = data as GuestType

    const {
        data: billingInfo,
        isLoading: isBillingInfoLoading,
        error: billingInfoError,
        isError: isBillingInfoError,
        isFetching
    } = useGetBillingInfo(Number(user?.id))

    useHandleError({
        isError: isBillingInfoError,
        error: billingInfoError
    })

    const onSuccess = () => {
        onSendBillingInfoSuccess?.()
        onClose()
    }

    return (
        <Modal onClose={onClose} minHeight={'668px'} fullScreen title={t('set_billing_info:page_title')}>
            <StyledBody>
                <StyledDescription>{t('set_billing_info:page_description')}</StyledDescription>
                {isBillingInfoLoading || isFetching ? (
                    <Spinner />
                ) : (
                    <BillingInfoForm defaultBillingInfo={billingInfo} onSendBillingInfoSuccess={onSuccess} />
                )}
            </StyledBody>
        </Modal>
    )
}
