import styled, {css} from 'styled-components'

export const StyledCardRoot = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 77px;
        width: 162px;
        max-height: 77px;
        max-width: 162px;
        background-color: #e8e8e8;
        border-radius: ${spacing}px;
        cursor: pointer;

        & img {
            max-width: 68px;
        }
    `}
`
