import {useLogout} from '@/features/authentication/queries/useLogout'
import {useMe} from '@/features/authentication/queries/useMe.ts'
import {UserStatusesE} from '@/features/constants/constants'
import * as routes from '@/utilities/constants/routeNames'
import {BLOCKED_USER_STATUSES} from '@/utilities/constants/user'
import {useEffect} from 'react'
import {Navigate, Outlet} from 'react-router-dom'

export const BlockedUserGuard = () => {
    const {data: user} = useMe()
    const {mutate} = useLogout()
    const isUserBlocked = !!user && BLOCKED_USER_STATUSES.includes(user.status)
    const isUserFraud = !!user && user?.status === UserStatusesE.FRAUD

    useEffect(() => {
        if (isUserFraud) {
            mutate()
        }
    }, [user])

    if (isUserBlocked) {
        return <Navigate to={routes.ROUTE_BLOCKED_USER} />
    } else {
        return <Outlet />
    }
}
