import {useRootStore} from '@/store'
import {selectGlobalTranslateStore} from '@/store/selectors'
import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {useGetChannels} from '@/features/chat/queries/useGetChannels'
import usePubnubInstance from '@/features/chat/hooks/usePubnubInstance.ts'
import {PubNubProvider} from 'pubnub-react'
import {useMe} from '@/features/authentication/queries/useMe.ts'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {getCorrectUserId} from '@/utilities/helpers'
import {useGetNotificationsHistory} from '@/features/notifications/queries/useGetNotificationsHistory'
import {useTranslation} from 'react-i18next'

// here the user always exists, otherwise I should be in a PublicRoute
export const PrivateDataLayer: React.FC = () => {
    const {data: user, loggedIn, isLoading} = useMe()
    const {i18n} = useTranslation()
    const {pubnubSDKInstance, isChatLoading} = usePubnubInstance(user, loggedIn)
    const {initialTranslateConfig} = useRootStore(selectGlobalTranslateStore)
    const correctUserId = user ? getCorrectUserId(user) : undefined
    // Here we initialize the chat channels from API server
    useGetChannels()
    useGetNotificationsHistory({appLang: i18n.language})

    useEffect(() => {
        !!correctUserId &&
            initialTranslateConfig({
                userID: String(correctUserId)
            })
    }, [])

    if (isChatLoading || isLoading) return <Spinner />

    return loggedIn ? (
        pubnubSDKInstance && (
            <PubNubProvider client={pubnubSDKInstance}>
                <Outlet />
            </PubNubProvider>
        )
    ) : (
        <Outlet />
    )
}
