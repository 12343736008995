import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from './keys'
import {QUERY_KEYS as AUTHENTICATION_QUERY_KEYS} from '@/features/authentication/queries/keys'
import {BILLING_API} from '../services/billing.http'
import {SendBillingInfoPayloadType} from '../types'

type useSendBillingInfoProps = {
    onSuccess: () => void
}

export const useSendBillingInfo = ({onSuccess}: useSendBillingInfoProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.send_billing_info],
        mutationFn: ({body, guestId}: {body: SendBillingInfoPayloadType; guestId: number}) =>
            BILLING_API.sendBillingInfo({body, guestId}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.billing_info]})
            queryClient.invalidateQueries({queryKey: [AUTHENTICATION_QUERY_KEYS.user]})
            onSuccess()
        }
    })
}
