import styled, {css} from 'styled-components'

export const NoPaymentMethodRootRoot = styled.div`
    ${() => css``}
`

export const StyledCCBillSection = styled.div`
    ${({theme: {typography, spacing}}) => css`
        font-size: ${typography.textXs};
        text-align: start;
        margin: ${spacing * 1.5}px 0 ${spacing * 2}px;
        & span {
            cursor: pointer;
            text-decoration: underline;
            margin-left: ${spacing / 2}px;
        }

        & svg {
            margin-left: ${spacing / 2}px;
        }
    `}
`

export const StyledAddOtherMethod = styled.div<{
    height: number
    isUpdateCreditCard?: boolean
    isMonthlySupport?: boolean
}>`
    ${({theme: {mediaQueries}, isUpdateCreditCard, isMonthlySupport, height}) => css`
        border-radius: 18px;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
        margin-top: 13px;
        height: 100%;
        min-height: ${isUpdateCreditCard
            ? '430px'
            : isMonthlySupport
              ? `calc(${height}px - 250px)`
              : `calc(${height}px - 425px)`};
        ${mediaQueries.m} {
            min-height: ${isUpdateCreditCard ? '430px' : isMonthlySupport ? '430px' : '260px'};
        }
        & iframe {
            border-radius: 18px;
            border: none;
        }
    `}
`
