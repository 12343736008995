import * as routeNames from '../constants/routeNames'

export const commonRoutes = {
    HOST_LANDING: {
        path: routeNames.ROUTE_HOST_LANDING,
        lazy: async () => await import('@pages/public-pages/host-landing/HostLanding.tsx')
    },
    LOGIN_HOST: {
        path: routeNames.ROUTE_LOGIN_HOST,
        lazy: async () => await import('@pages/public-pages/login/login-host/LoginHost.tsx')
    },
    LOGIN_GUEST: {
        path: routeNames.ROUTE_LOGIN_GUEST,
        lazy: async () => await import('@pages/public-pages/login/login-guest/LoginGuest.tsx')
    },
    SIGNUP_HOST: {
        path: routeNames.ROUTE_SIGNUP_HOST,
        lazy: async () => await import('@pages/public-pages/sign-up/sign-up-host/SignUpHost.tsx')
    },
    SIGNUP_HOST_SPECIAL: {
        path: routeNames.ROUTE_SIGNUP_SPECIAL_HOST,
        lazy: async () => await import('@pages/public-pages/sign-up/sign-up-host/SignUpHost.tsx')
    },
    SIGNUP_GUEST: {
        path: routeNames.ROUTE_SIGNUP_GUEST,
        lazy: async () => await import('@pages/public-pages/sign-up/sign-up-guest/SignUpGuest.tsx')
    },
    SIGNUP_GUEST_SOCIAL: {
        path: routeNames.ROUTE_SIGNUP_GUEST_SOCIAL,
        lazy: async () => await import('@pages/public-pages/sign-up/sign-up-guest-social/SignUpGuestSocial.tsx')
    },
    SIGNUP_REPRESENTATIVE: {
        path: routeNames.ROUTE_SIGNUP_REPRESENTATIVE,
        lazy: async () => await import('@pages/public-pages/sign-up/sign-up-representative/SignUpRepresentative.tsx')
    },
    SIGNUP_SUCCESS: {
        path: routeNames.ROUTE_SIGNUP_SUCCESS,
        lazy: async () => await import('@pages/public-pages/sign-up/sign-up-success/SignUpSuccess.tsx')
    },
    SIGNUP_ACTIVATION: {
        path: routeNames.ROUTE_SIGNUP_ACTIVATION,
        lazy: async () => await import('@pages/public-pages/sign-up/sign-up-activation/SignUpActivation.tsx')
    },
    LOGIN_EMAIL_VERIFICATION: {
        path: routeNames.ROUTE_LOGIN_EMAIL_VERIFICATION,
        lazy: async () => await import('@pages/public-pages/login/login-email-verification/LoginEmailVerification.tsx')
    },
    SIGNUP_EMAIL_VERIFICATION: {
        path: routeNames.ROUTE_SIGNUP_EMAIL_VERIFICATION,
        lazy: async () =>
            await import('@pages/public-pages/sign-up/sign-up-email-verification/SignUpEmailVerification.tsx')
    },
    LINK_ACCOUNTS: {
        path: routeNames.ROUTE_LINK_ACCOUNTS,
        lazy: async () => await import('@pages/public-pages/login/link-accounts/LinkAccounts.tsx')
    },
    TERMS_AND_CONDITIONS: {
        path: routeNames.ROUTE_TERMS_AND_CONDITIONS,
        lazy: async () => await import('@pages/all-user-role-pages/terms-and-conditions/TermsAndConditions.tsx')
    },
    PRIVACY_POLICY: {
        path: routeNames.ROUTE_PRIVACY_POLICY,
        lazy: async () => await import('@pages/public-pages/static-pages/StaticPages.tsx')
    },
    FAQ_HOST: {
        path: routeNames.ROUTE_FAQ_HOST,
        lazy: async () => await import('@pages/public-pages/static-pages/StaticPages.tsx')
    },
    FAQ_GUEST: {
        path: routeNames.ROUTE_FAQ_GUEST,
        lazy: async () => await import('@pages/public-pages/static-pages/StaticPages.tsx')
    },
    COOKIE_POLICY: {
        path: routeNames.ROUTE_COOKIE_POLICY,
        lazy: async () => await import('@pages/public-pages/static-pages/StaticPages.tsx')
    },
    TOS_GUEST: {
        path: routeNames.ROUTE_TOS_GUEST,
        lazy: async () => await import('@pages/public-pages/static-pages/StaticPages.tsx')
    },
    TOS_HOST: {
        path: routeNames.ROUTE_TOS_HOST,
        lazy: async () => await import('@pages/public-pages/static-pages/StaticPages.tsx')
    },
    COMMUNITY_GUIDELINES: {
        path: routeNames.ROUTE_COMMUNITY_GUIDELINES,
        lazy: async () => await import('@pages/public-pages//static-pages/StaticPages.tsx')
    },
    A_WORD_FROM_PRESIDENT: {
        path: routeNames.ROUTE_A_WORD_FROM_PRESIDENT,
        lazy: async () => await import('@pages/public-pages/static-pages/StaticPages.tsx')
    },
    OUR_MISSION: {
        path: routeNames.ROUTE_OUR_MISSION,
        lazy: async () => await import('@pages/public-pages/static-pages/StaticPages.tsx')
    },
    WHAT_IS_MORGI: {
        path: routeNames.ROUTE_WHAT_IS_MORGI,
        lazy: async () => await import('@pages/public-pages/static-pages/StaticPages.tsx')
    },
    WHO_WE_ARE: {
        path: routeNames.ROUTE_WHO_WE_ARE,
        lazy: async () => await import('@pages/public-pages/static-pages/StaticPages.tsx')
    },
    RESET_PASSWORD: {
        path: routeNames.ROUTE_RESET_PASSWORD,
        lazy: async () => await import('@pages/public-pages/reset-password/ResetPassword.tsx')
    },
    PASSWORD_RECOVERY: {
        path: routeNames.ROUTE_PASSWORD_RECOVERY,
        lazy: async () => await import('@pages/public-pages/password-recovery/PasswordRecovery.tsx')
    },
    UNSUBSCRIBE: {
        path: routeNames.ROUTE_UNSUBSCRIBE,
        lazy: async () => await import('@pages/public-pages/unsubscribe/Unsubscribe.tsx')
    },
    // CHAT: {
    //     path: routeNames.ROUTE_CHAT,
    //     lazy: async () => await import('@pages/all-user-role-pages/chat/Chat.tsx')
    // },
    BLOCKED_USER: {
        path: routeNames.ROUTE_BLOCKED_USER,
        lazy: async () => await import('@/pages/all-user-role-pages/blocked-user/BlockedUser.tsx')
    },
    CHANGE_PASSWORD: {
        path: routeNames.ROUTE_CHANGE_PASSWORD,
        lazy: async () => await import('@/pages/all-user-role-pages/change-password/ChangePassword.tsx')
    },
    ADD_NEW_INFO_PAGE: {
        path: routeNames.ROUTE_ADD_NEW_INFO_PAGE,
        lazy: async () => await import('@/pages/all-user-role-pages/add-new-info/AddNewInfo.tsx')
    },
    EDIT_PROFILE: {
        path: routeNames.ROUTE_EDIT_PROFILE,
        lazy: async () => await import('@/pages/all-user-role-pages/edit-profile/EditProfile.tsx')
    },
    EDIT_REPRESENTATIVE: {
        path: routeNames.ROUTE_EDIT_REPRESENTATIVE,
        lazy: async () => await import('@/pages/representative-pages/edit-representative/EditRepresentative.tsx')
    },
    COMPLETE_PROFILE: {
        path: routeNames.ROUTE_COMPLETE_PROFILE,
        lazy: async () => await import('@pages/guest-pages/complete-profile/CompleteProfile.tsx')
    },
    // MESSAGE_CENTER: {
    //     path: routeNames.ROUTE_MESSAGE_CENTER,
    //     lazy: async () => await import('@/pages/all-user-role-pages/message-center/MessageCenter.tsx')
    // },
    MESSAGE_CENTER_CHAT: {
        path: routeNames.ROUTE_MESSAGE_CENTER_CHAT,
        lazy: async () => await import('@/pages/all-user-role-pages/message-center-chat/MessageCenterChat.tsx')
    },
    DELETE_PROFILE: {
        path: routeNames.ROUTE_DELETE_PROFILE,
        lazy: async () => await import('@/pages/all-user-role-pages/delete-profile/DeleteProfile.tsx')
    }
}

export const hostRoutes = {
    HOST_MY_PROFILE: {
        path: routeNames.ROUTE_HOST_MY_PROFILE,
        lazy: async () => await import('@pages/host-pages/my-profile/MyProfile.tsx')
    },
    HOST_PHOTO_UPLOAD: {
        path: routeNames.ROUTE_HOST_PHOTO_UPLOAD,
        lazy: async () => await import('@/pages/host-pages/host-photo-upload/HostPhotoUpload.tsx')
    },
    HOST_VIDEO_UPLOAD: {
        path: routeNames.ROUTE_HOST_VIDEO_UPLOAD,
        lazy: async () => await import('@/pages/host-pages/host-video-upload/HostVideoUpload.tsx')
    },
    TRANSACTION_HISTORY: {
        path: routeNames.ROUTE_TRANSACTION_HISTORY,
        lazy: async () => await import('@pages/all-user-role-pages/transaction-history/TransactionHistory.tsx')
    },
    HOST_GENERAL_TIPS: {
        path: routeNames.ROUTE_HOST_GENERAL_TIPS,
        lazy: async () => await import('@/pages/host-pages/host-general-tips/HostGeneralTips.tsx')
    },
    HOST_MORGI_WINNERS: {
        path: routeNames.ROUTE_HOST_MORGI_WINNERS,
        lazy: async () => await import('@/pages/host-pages/host-morgi-winners/HostMorgiWinners.tsx')
    },
    HOST_DAILY_INSPIRATION: {
        path: routeNames.ROUTE_HOST_DAILY_INSPIRATION,
        lazy: async () => await import('@/pages/host-pages/host-daily-inspiration/HostDailyInspiration.tsx')
    },
    ACTIVE_GIFTING_GUESTS: {
        path: routeNames.ROUTE_ACTIVE_GIFTING_GUESTS,
        lazy: async () => await import('@pages/host-pages/host-side-gifts/HostSideGifts.tsx')
    },
    DAILY_HOST: {
        path: routeNames.ROUTE_DAILY_HOST,
        lazy: async () => await import('@/pages/host-pages/daily-host/DailyHost.tsx')
    },
    GUEST_GIF_HISTORY: {
        path: routeNames.ROUTE_GUEST_GIFT_HISTORY,
        lazy: async () => await import('@/pages/host-pages/guest-gift-history/GuestGiftHistory.tsx')
    },
    REFER_A_GUEST: {
        path: routeNames.ROUTE_REFER_A_GUEST,
        lazy: async () => await import('@/pages/host-pages/refer-a-guest/ReferAGuest.tsx')
    },
    WELCOME_HOST: {
        path: routeNames.ROUTE_WELCOME_HOST,
        lazy: async () => await import('@/pages/host-pages/welcome-host/WelcomeHost.tsx')
    },
    HOST_SCORE: {
        path: routeNames.ROUTE_HOST_SCORE,
        lazy: async () => await import('@/pages/host-pages/host-score/HostScore.tsx')
    },
    TELEGRAM_BOT: {
        path: routeNames.ROUTE_TELEGRAM_BOT,
        lazy: async () => await import('@/pages/host-pages/telegram-bot/TelegramBot.tsx')
    },
    TELEGRAM_TIPS: {
        path: routeNames.ROUTE_TELEGRAM_TIPS,
        lazy: async () => await import('@/pages/host-pages/telegram-tips/TelegramTips.tsx')
    },
    CONNECT_TELEGRAM_PAGE: {
        path: routeNames.ROUTE_CONNECT_TELEGRAM_PAGE,
        lazy: async () => await import('@/pages/host-pages/connect-telegram/ConnectTelegram.tsx')
    },
    DISCORD_COMMUNITY: {
        path: routeNames.ROUTE_DISCORD_COMMUNITY,
        lazy: async () => await import('@/pages/host-pages/discord-community/DiscordCommunity.tsx'),
        handle: {
            themeMode: 'dark'
        }
    },
    APPROACH_FIRST: {
        path: routeNames.ROUTE_APPROACH_FIRST,
        lazy: async () => await import('@/pages/host-pages/approach-first/ApproachFirst.tsx')
    },
    INVITE_FRIENDS: {
        path: routeNames.ROUTE_INVITE_FRIENDS,
        lazy: async () => await import('@pages/all-user-role-pages/invite-friends/InviteFriends.tsx')
    },
    HOST_PAYMENT_METHOD: {
        path: routeNames.ROUTE_HOST_PAYMENT_METHOD,
        lazy: async () => await import('@pages/host-pages/host-payment-method/HostPaymentMethod.tsx')
    },
    HOST_PROFILE: {
        path: routeNames.ROUTE_HOST_PROFILE,
        lazy: async () => await import('@pages/all-user-role-pages/host-profile/HostProfile.tsx')
    },
    HOST_MYSELF: {
        path: routeNames.ROUTE_HOST_MYSELF,
        lazy: async () => await import('@/pages/all-user-role-pages/host-profile/HostProfile.tsx')
    },
    TOP_FAN_GUESTSBOARD: {
        path: routeNames.ROUTE_TOP_FAN_GUESTSBOARD,
        lazy: async () => await import('@/pages/all-user-role-pages/top-fan-host-board/TopFanHostBoard.tsx')
    },
    WELCOME_BACK_PAGE: {
        path: routeNames.ROUTE_WELCOME_BACK_PAGE,
        lazy: async () => await import('@/pages/all-user-role-pages/welcome-back/WelcomeBack.tsx'),
        handle: {
            themeMode: 'dark'
        }
    },
    MANAGE_REPS: {
        path: routeNames.ROUTE_MANAGE_REPS,
        lazy: async () => await import('@/pages/host-pages/manage-reps/ManageReps.tsx')
    },
    UPLOAD_YOUR_SURPRISE: {
        path: routeNames.ROUTE_UPLOAD_YOUR_SURPRISE,
        lazy: async () => await import('@/pages/host-pages/upload-your-surprise/UploadYourSurprisePage.tsx')
    }
}

export const guestRoutes = {
    HOME: {
        path: routeNames.ROUTE_HOME,
        lazy: async () => await import('@pages/guest-pages/home/Home.tsx')
    },
    SAVED_HOSTS: {
        path: routeNames.ROUTE_SAVED_HOSTS,
        lazy: async () => await import('@/pages/guest-pages/saved-hosts/SavedHosts.tsx')
    },
    BIRTHDAYS: {
        path: routeNames.ROUTE_BIRTHDAYS,
        lazy: async () => await import('@/pages/guest-pages/birthdays/Birthdays.tsx'),
        handle: {
            themeMode: 'dark'
        }
    },
    REFER_A_HOST: {
        path: routeNames.ROUTE_REFER_A_HOST,
        lazy: async () => await import('@/pages/guest-pages/refer-a-host/ReferAHost.tsx')
    },
    MY_MICROMORGI_BALANCE: {
        path: routeNames.ROUTE_MY_MICROMORGI_BALANCE,
        lazy: async () => await import('@pages/guest-pages/my-micromorgi-balance/MyMicromorgiBalance.tsx')
    },
    ACTIVE_GIFTS: {
        path: routeNames.ROUTE_ACTIVE_GIFTS,
        lazy: async () => await import('@pages/guest-pages/guest-side-gifts/GuestSideGifts.tsx')
    },
    PATH_FILTER: {
        path: routeNames.ROUTE_PATH_FILTER,
        lazy: async () => await import('@/pages/guest-pages/path-filter/PathFilter.tsx')
    },
    SECOND_CHANCE: {
        path: routeNames.ROUTE_SECOND_CHANCE,
        lazy: async () => await import('@/pages/guest-pages/second-chance/SecondChance.tsx')
    },
    GUEST_QUOTES: {
        path: routeNames.ROUTE_GUEST_QUOTES,
        lazy: async () => await import('@pages/guest-pages/guest-quotes/GuestQuotes.tsx')
    },
    CHECK_PAYMENT: {
        path: routeNames.ROUTE_CHECK_PAYMENT,
        lazy: async () => await import('@/pages/guest-pages/check-payment/CheckPayment.tsx')
    },
    UPDATE_CREDIT_CARD: {
        path: routeNames.ROUTE_UPDATE_CREDIT_CARD,
        lazy: async () => await import('@/pages/guest-pages/update-credit-card/UpdateCreditCard.tsx')
    },
    UPDATE_RECURRING_TRANSACTION: {
        path: routeNames.ROUTE_UPDATE_RECURRING_TRANSACTION,
        lazy: async () =>
            await import('@/pages/guest-pages/update-recurring-transaction/UpdateRecurringTransaction.tsx')
    },
    COUPON_HISTORY: {
        path: routeNames.ROUTE_COUPON_HISTORY,
        lazy: async () => await import('@/pages/guest-pages/coupon-history/CouponHistory.tsx')
    },
    ON_BOARD_TOUR: {
        path: routeNames.ROUTE_ON_BOARD_TOUR,
        lazy: async () => await import('@/pages/guest-pages/on-board-tour/OnBoardTour.tsx')
    },
    SIGNIN_AFTER_EMAIL: {
        path: routeNames.ROUTE_SIGNIN_AFTER_EMAIL,
        lazy: async () => await import('@pages/guest-pages/sign-in-after-email/SignInAfterEmail.tsx')
    },
    MY_MOMENTS_FEED: {
        path: routeNames.ROUTE_MY_MOMENTS_FEED,
        lazy: async () => await import('@/pages/guest-pages/my-moments/MyMoments')
    },
    WHOS_TOP_FAN_YOU_ARE: {
        path: routeNames.ROUTE_WHOS_TOP_FAN_YOU_ARE,
        lazy: async () => await import('@/pages/guest-pages/whos-top-fan-you-are/WhosTopFanYouAre.tsx')
    },
    BILLING_INFO: {
        path: routeNames.ROUTE_BILLING_INFO,
        lazy: async () => await import('@/pages/guest-pages/billing-info/BillingInfo.tsx')
    }
}

export const goalRoutes = {
    MY_GOALS: {
        path: routeNames.ROUTE_MY_GOALS,
        lazy: async () => await import('@pages/host-pages/my-goals/MyGoals.tsx')
    },
    CREATE_GOAL: {
        path: routeNames.ROUTE_CREATE_GOAL,
        lazy: async () => await import('@pages/host-pages/create-goal/CreateGoal.tsx')
    },
    GOAL: {
        path: routeNames.ROUTE_GOAL,
        lazy: async () => await import('@pages/all-user-role-pages/goal/Goal.tsx')
    },
    GOAL_PROOF: {
        path: routeNames.ROUTE_GOAL_PROOF,
        lazy: async () => await import('@/pages/host-pages/goal-proof/GoalProof.tsx')
    },
    GOALS_TOUR: {
        path: routeNames.ROUTE_HOST_GOALS_TOUR,
        lazy: async () => await import('@pages/host-pages/goals-tour/GoalsTour.tsx')
    },
    SUPPORTER_LEADER_PAGE: {
        path: routeNames.ROUTE_SUPPORTER_GUEST_PAGE,
        lazy: async () => await import('@/pages/host-pages/supporter-guest-page/SupporterGuest')
    },
    SELECT_GOAL_TYPE: {
        path: routeNames.ROUTE_SELECT_GOAL_TYPE,
        lazy: async () => await import('@pages/host-pages/select-goal-type/SelectGoalType.tsx')
    },
    GOALS_MAIN_PAGE: {
        path: routeNames.ROUTE_GOALS_MAIN_PAGE,
        lazy: async () => await import('@pages/guest-pages/goals-main-page/GoalsMain.tsx')
    },
    SAVED_GOALS: {
        path: routeNames.ROUTE_SAVED_GOALS,
        lazy: async () => await import('@pages/guest-pages/saved-goals/SavedGoals.tsx')
    },
    SUPPORTED_GOALS: {
        path: routeNames.ROUTE_SUPPORTED_GOALS,
        lazy: async () => await import('@pages/guest-pages/supported-goals/SupportedGoals.tsx')
    },
    PAST_GOALS: {
        path: routeNames.ROUTE_PAST_GOALS,
        lazy: async () => await import('@pages/guest-pages/past-goals/PastGoals.tsx')
    },
    WHAT_ARE_GOALS: {
        path: routeNames.ROUTE_WHAT_ARE_GOALS,
        lazy: async () => await import('@pages/guest-pages/what-are-goals/WhatAreGoals.tsx')
    },
    DETAILED_TRANSACTION: {
        path: routeNames.ROUTE_DETAILED_TRANSACTION,
        lazy: async () => await import('@pages/host-pages/detailed-transaction/DetailedTransaction.tsx')
    }
}

export const REDIRECT_MAP = {
    leader: routeNames.ROUTE_HOME,
    rookie: routeNames.ROUTE_HOST_MY_PROFILE,
    representative: routeNames.ROUTE_MESSAGE_CENTER
}
