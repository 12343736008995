import axios from '@/clientAPI.ts'
import * as types from '../types'
import {GetUnlockedMessagePayload, UnlockedMediaMessagePayload} from '../types'
import {ChatFileSchema, ChatReportCategoriesResponseSchema, ChannelSchema} from './chat.schema'

export const CHAT_API = {
    // TODO: This is a duplicated of getChannels
    // and it is using the non-optimized version of the endpoint
    getChatChannels: async (): Promise<{active: types.Channel[]; inactive: types.Channel[]}> => {
        const {data} = await axios.get(`/channels/old`)
        return data
    },
    sendChatFile: async (channelID: string, type: string, formDataObject: FormData): Promise<types.ChatFileType> => {
        const url = `/channels/${channelID}/attachments/send_${type}`
        const {data} = await axios.post(url, formDataObject, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
        return ChatFileSchema.parse(data)
    },
    setLastReadTimetoken: async (channelId: string, timetoken: string) => {
        const {data} = await axios.post(`/channels/${channelId}/reads`, {timetoken})
        return data
    },
    pauseConnection: async (channelId: string) => {
        const {data} = await axios.post(`/channels/${channelId}/pause`)
        return data
    },
    sendMessage: async (payload: types.ChannelMessage) => {
        const {data} = await axios.post(`/channels/${payload.channelId}/messages`, payload)
        return data
    },
    sendGroupMessage: async ({groupId, params}: types.GroupMessageParams) => {
        const {data} = await axios.post(`/groups/${groupId}/messages`, params)
        return data
    },
    initChat: async () => {
        return await axios.post(`/channels/init`)
    },
    getChannels: async () => {
        const {data} = await axios.get<types.ChannelsList>('/channels/old/light')
        return data
    },
    getChannelDetails: async (channelName?: string) => {
        const {data} = await axios.get<types.Channel>(`/channels/name/${channelName}`)
        return ChannelSchema.parse(data)
    },
    getAnimationDetails: async (animationId?: number) => {
        const {data} = await axios.get<types.Animation>(`/animations/${animationId}`)
        return data
    },
    getAnimationsList: async (order_by_chat?: boolean) => {
        const {data} = await axios.get<types.Animation[]>('/animations', {params: {order_by_chat}})
        return data
    },
    getChatFile: async (channelId?: number, attachmentId?: number) => {
        const {data} = await axios.get<types.ChatFileType>(`/channels/${channelId}/attachments/${attachmentId}`)
        return data
    },
    getMessageLanguage: async (text: string) => {
        const {data} = await axios.get<types.MessageLanguageResponse>('/translations/get-language', {params: {text}})
        return data
    },
    getChatReportCategories: async () => {
        const url = `/channels/reports/categories`
        const {data} = await axios.get<types.ChatReportCategoriesResponse>(url)
        return ChatReportCategoriesResponseSchema.parse(data)
    },
    sendChatReport: async ({channelID, params}: types.ReportPayload) => {
        const url = `/channels/${channelID}/reports`
        return await axios.post(url, params)
    },
    getGroupsList: async () => {
        const {data} = await axios.get<types.GroupChatItem[]>('/groups')
        return data
    },
    unlockTextMessage: async (id: string) => {
        const {data} = await axios.post(`paid-messages/${id}/unlock`)
        return data
    },
    unlockedMediaMessage: async (payload: UnlockedMediaMessagePayload) => {
        const {data} = await axios.post(`channels/${payload.channelID}/attachments/${payload.attachmentId}/unlock`)
        return data
    },
    getUnlockedMessages: async (channelID: string, payload: GetUnlockedMessagePayload) => {
        const {data} = await axios.post(`channels/${channelID}/attachments`, payload)
        return data
    },
    createChatWithGuest: async (guestId: number) => {
        const {data} = await axios.post(`/leaders/${guestId}/channels`)
        return data
    },
    getLockPaidTextMessage: async (id?: number) => {
        const {data} = await axios.get<types.UnlockedMessage>(`paid-messages/${id}`)
        return data
    }
}
