import {useMemo} from 'react'
import {getColor, remapNotificationType, shouldGoTo} from '../../utilities/utilities'
import NotificationIcon from '../notificationIcon/NotificationIcon'
import {NotificationsType} from '../../types'
import {
    StyledDate,
    StyledDescriptionContainer,
    StyledIconBox,
    StyledNotificationCard,
    StyledNotificationTitle
} from './style'
import {useTranslation} from 'react-i18next'
import dayjs from '@/dayjs'
import {NotificationsTypesE} from '../../services/notifications.schema'
import {useNavigate} from 'react-router-dom'
import {
    ROUTE_EDIT_PROFILE,
    ROUTE_HOME,
    ROUTE_HOST_PAYMENT_METHOD,
    ROUTE_TELEGRAM_BOT
} from '@/utilities/constants/routeNames'
import {useMe} from '@/features/authentication/queries/useMe'
import {User} from '@/features/authentication/types'
import {GoalType} from '@/features/goal/types'
import {useFetchGoal} from '@/features/goal/queries/useFetchGoal'
import {useTheme} from 'styled-components'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/features/moengage/queries/keys'
import {useRootStore} from '@/store'
import {selectChatChannels} from '@/store/selectors'
import {generateChatChannelName} from '@/utilities/helpers'

type NewNotification = {
    notification: NotificationsType
    old?: boolean
    closeNotification: () => void
}

export const NotificationToast = ({notification, old, closeNotification}: NewNotification) => {
    const {data} = useMe()
    const theme = useTheme()
    const user = data as User
    const {t} = useTranslation()
    const navigate = useNavigate()
    const hasGoalId = !!notification?.goal_id
    const {data: goal} = useFetchGoal(hasGoalId ? `${notification.goal_id}` : undefined)
    const goalsList = (user?.type_attributes as {goals?: GoalType[]})?.goals
    const typeRemapped = useMemo(() => remapNotificationType(notification.type), [notification.type])
    const type = notification.type || notification.notification_type.type
    const color = getColor(typeRemapped, theme)
    const queryClient = useQueryClient()
    const channels = useRootStore(selectChatChannels)

    const redirectToPath = useMemo(() => {
        switch (type) {
            case NotificationsTypesE.HOST_REJECTED_PAYMENT_ID_CARD:
            case NotificationsTypesE.ID_CARD_REJECTED:
            case NotificationsTypesE.BACK_ID_CARD_REJECTED:
            case NotificationsTypesE.FRONT_ID_CARD_REJECTED:
            case NotificationsTypesE.SELFIE_ID_CARD_REJECTED:
                return () => {
                    navigate(ROUTE_EDIT_PROFILE, {state: {fromProfileError: true}})
                    closeNotification()
                }
            case NotificationsTypesE.HOST_REJECTED_PAYMENT_NO_METHOD:
                return () => {
                    navigate(ROUTE_HOST_PAYMENT_METHOD)
                    closeNotification()
                }
            case NotificationsTypesE.HOST_CHANGE_GIFT_AMOUNT:
            case NotificationsTypesE.TELEGRAM_BOT:
                return () => {
                    navigate(ROUTE_TELEGRAM_BOT)
                    closeNotification()
                }
            case NotificationsTypesE.GOT_BONUS_COUPON:
            case NotificationsTypesE.GOT_REFUNDED_GIFT_COUPON:
                return
            case NotificationsTypesE.HOST_LOGIN:
                return () => {
                    navigate(ROUTE_HOME)
                    closeNotification()
                }
            default:
                return
        }
    }, [type])

    const invalidateMoengage = () => {
        queryClient.invalidateQueries({queryKey: [QUERY_KEYS.moengage_campaign]})
    }

    const onClickAction = () => {
        const channelId =
            user && notification?.ref_user_id && generateChatChannelName(user.id, user.type, notification?.ref_user_id)
        const isChannelExist = !!channelId && !!channels[channelId]

        const path = shouldGoTo({
            type,
            typeRemapped,
            notification,
            user,
            goalsList,
            goal,
            invalidateMoengage,
            channelId: isChannelExist ? channelId : null
        })

        if (path) {
            closeNotification()
            navigate(path)
        } else {
            redirectToPath?.()
        }
    }

    const notificationDate = useMemo(() => {
        const date = dayjs(notification.created_at)
        if (date.format('DD') === dayjs().format('DD')) {
            return date.format('H:mm')
        } else if (date.format('D') === (parseInt(dayjs().format('D')) - 1).toString()) {
            return `${t('common:yesterday')} ${date.format('H:mm')}`
        } else {
            return date.format('DD MMM, H:mm')
        }
    }, [notification.created_at])

    return (
        <StyledNotificationCard color={color} old={old} onClick={onClickAction}>
            <StyledDescriptionContainer>
                <StyledIconBox>
                    <NotificationIcon old={old} color={color} type={typeRemapped} />
                </StyledIconBox>
                <div>
                    <StyledNotificationTitle>
                        {notification.type === NotificationsTypesE.MOENGAGE_NOTIFICATION
                            ? t('common:notification_moengage_title')
                            : notification?.notification_type?.title}
                    </StyledNotificationTitle>
                    {notification?.content
                        ? notification.content
                        : typeof notification.campaign_notification === 'object' &&
                          notification.campaign_notification?.message}
                </div>
            </StyledDescriptionContainer>
            <StyledDate>{notification.created_at && <div>{notificationDate}</div>}</StyledDate>
        </StyledNotificationCard>
    )
}
