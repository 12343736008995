import axios from '@/clientAPI.ts'
import {AxiosResponse} from 'axios'
import {BillingInfoResponseType, SendBillingInfoPayloadType} from '../types'

export const BILLING_API = {
    sendBillingInfo: async ({
        body,
        guestId
    }: {
        body: SendBillingInfoPayloadType
        guestId: number
    }): Promise<AxiosResponse<SendBillingInfoPayloadType>> => {
        const {street, customer_name, email, phone_number} = body
        const url = `/users/${guestId}/invoicing-profile`
        const {data} = await axios.post(url, {customer_name, email, phone_number, ...street})
        return data
    },
    editBillingInfo: async ({
        body,
        guestId
    }: {
        body: SendBillingInfoPayloadType
        guestId: number
    }): Promise<AxiosResponse<SendBillingInfoPayloadType>> => {
        const {street, customer_name, email, phone_number} = body
        const url = `/users/${guestId}/invoicing-profile`
        const {data} = await axios.patch(url, {customer_name, email, phone_number, ...street})
        return data
    },
    getBillingInfo: async (guestId: number): Promise<BillingInfoResponseType> => {
        const url = `/users/${guestId}/invoicing-profile`
        const {data} = await axios.get(url)
        return data
    }
}
