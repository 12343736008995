import {z} from 'zod'

const boolEnvvar = z
    .string()
    .optional()
    .transform(s => s == 'true')

export const envVars = z
    .object({
        VITE_APP_TITLE: z.string().optional(),
        VITE_API_ENDPOINT: z.string().url(),
        VITE_APP_PUBLIC_WEBSITE: z.string().url(),
        VITE_APP_ROOKIE_TELEGRAM_BLOCK_DISABLED: boolEnvvar,
        VITE_APP_CUSTOMER_CARE_EMAIL: z.string().email(),
        VITE_APP_CUSTOMER_CARE_GUEST_LINK: z.string().url().optional(),
        VITE_APP_CUSTOMER_CARE_HOST_LINK: z.string().url().optional(),
        IS_SENTRY_ENABLED: boolEnvvar,
        ENABLE_CLARITY: boolEnvvar,
        CLARITY_PROJ_ID: z.string().optional(),
        SENTRY_ORG: z.string().email().optional(),
        SENTRY_PROJ: z.string().optional(),
        SENTRY_AUTH: z.string().optional(),
        VITE_WHATSAPP_URL: z.string().optional(),
        VITE_APP_GIPHY_API_KEY: z.string().optional(),
        VITE_APP_DISCORD_GROUP: z.string().optional(),
        VITE_APP_LEARNDASH_COURSE_URL: z.string().optional(),
        VITE_APP_FACEBOOK_GROUP: z.string().optional(),
        VITE_APP_PUBNUB_PUBLISH_KEY: z.string(),
        VITE_APP_PUBNUB_SUBSCRIBE_KEY: z.string(),
        VITE_APP_IUBENDA_SITE_ID: z.string().optional(),
        VITE_APP_IUBENDA_COOKIE_POLICY_ID: z.string().optional(),
        VITE_APP_MIXPANEL_PROJECT_TOKEN: z.string().optional(),
        VITE_APP_TELEGRAM_BOT_NAME: z.string().optional(),
        VITE_APP_TELEGRAM_BOT_URL: z.string().optional(),
        VITE_APP_PUSHER_APP_KEY: z.string(),
        VITE_APP_PUSHER_APP_CLUSTER: z.string(),
        VITE_APP_PUSHER_AUTH_ENDPOINT: z.string(),
        VITE_APP_FACEBOOK_APP_ID: z.string().optional(),
        VITE_APP_GOOGLE_CLIENT_ID: z.string().optional(),
        VITE_APP_GOOGLE_MAP_API_KEY: z.string().optional(),
        VITE_APP_GOOGLE_TAG_MANAGER_ID: z.string().optional(),
        PRELAUNCH_USER: z.string().optional(),
        PRELAUNCH_PASS: z.string().optional(),
        VITE_APP_MOENGAGE_APP_ID: z.string().optional(),
        VITE_APP_MOENGAGE_APP_CLUSTER: z.string().optional()
    })
    .parse(import.meta.env)
