import styled, {css} from 'styled-components'

export const StyledAmountContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
        align-items: center;
        height: 100%;
    `}
`
export const StyledAmountIconGem = styled.div`
    & svg {
        min-width: 40px;
        height: 100%;
    }
`
export const StyledAmount = styled.span`
    ${({theme: {palette, typography}}) => css`
        ${typography.text4Xl}
        color: ${palette.primary[900]};
        font-weight: 700;
    `}
`
export const StyledPrice = styled.span`
    ${({theme: {palette, typography}}) => css`
        ${typography.textSm}
        color: ${palette.neutral[50]};
        font-weight: 400;
        align-self: center;
        white-space: nowrap;
        margin-left: auto;
        text-align: end;
    `}
`
export const StyledAmountRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`
export const StyledLockIcon = styled.span`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
`
export const ToolTipContent = styled.div`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textXs}
        max-width: 350px;
        transition-duration: 300ms;
        padding: ${spacing / 2}px ${spacing}px;
        width: 230px;
        text-align: center;
        font-weight: 400;
        line-height: 16px;
    `}
`

export const ToolTipLink = styled.a`
    ${({theme: {palette}}) => css`
        cursor: pointer;
        color: ${palette.primary[900]};
        border-bottom: 1px solid ${palette.primary[900]};
    `}
`

export const StyledToolTipWrapper = styled.div`
    ${() => css`
        margin-left: auto;
    `}
`

export const StyledWrapper = styled.div`
    ${({disabled}: {disabled: boolean}) => css`
        opacity: ${disabled && 0.3};
        cursor: ${disabled && 'not-allowed'};
    `}
`

export const StyledSurprisesWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing / 2}px;
        & span {
            font-weight: 700;
            color: ${palette.primary[900]};
        }
    `}
`
