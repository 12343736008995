import styled, {css} from 'styled-components'

export const PaymentPlatformSectionRoot = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: repeat(2, auto);
        gap: ${spacing * 2}px;
    `}
`

export const StyledShowMore = styled.div`
    ${({theme: {palette, typography}}) => css`
        cursor: pointer;
        color: ${palette.primary[700]};
        text-decoration: underline;
        ${typography.textSm}
    `}
`
