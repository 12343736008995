import {MicromorgiIcon} from '@/assets/icons/icons'
import {Modal} from '@/components/ui/modal/Modal'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useRootStore} from '@/store'
import {
    selectChoosePaymentsMethodModal,
    selectErrorModal,
    selectPaidGifsModal,
    selectSendMegaGiftModal,
    selectSuccessMegaGiftOverlay
} from '@/store/selectors'
import megaGift from '@assets/images/megaGift.png'
import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSendMegaGift} from '../queries/useSendMegaGift'
import {
    StyledSendMegaGiftModalContainer,
    StyledSendMegaGiftModalDescription,
    StyledSendMegaGiftModalDescriptionCost,
    StyledSendMegaGiftModalImage,
    StyledSendMegaGiftModalSendButton,
    StyledSendMegaGiftModalWrapper
} from './style'
import {MEGA_GIFT} from '@/featuresConfig'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import { BillingInfoModal } from '@/features/billing/components/billing-info-modal/BillingInfoModal'
import { GuestType } from '@/features/authentication/types'
import { useMe } from '@/features/authentication/queries/useMe'

export const SendMegaGiftModal: FC = () => {
    const {t} = useTranslation()
    const mixpanel = useMixPanel()
    const {data} = useMe()
    const user = data as GuestType
    const {
        closeModal: closeSendMegaGiftModal,
        isOpen: isOpenSendMegaGiftModal,
        hostID,
        onSuccessSendGift
    } = useRootStore(selectSendMegaGiftModal)
    const {closeModal: closePaidGifsModal, isOpen: isOpenPaidGifsModal} = useRootStore(selectPaidGifsModal)
    const {openModal: openSuccessMegaGiftOverlay} = useRootStore(selectSuccessMegaGiftOverlay)
    const {
        mutate: sendMegaGift,
        isPending: isLoadingSendMegaGift,
        error: errorSendMegaGift,
        data: dataSendMegaGift
    } = useSendMegaGift({hostID: String(hostID)})
    const {openErrorModal} = useRootStore(selectErrorModal)
    const {openModal: openChoosePaymentsMethodModal, closeModal: closeChosePaymentMethodModal} = useRootStore(
        selectChoosePaymentsMethodModal
    )
    const hasBillingData = !!user?.type_attributes?.has_invoicing_profile
    const [showBillingInfoModal, setShowBillingInfoModal] = useState(false)

    const onSendMegaGiftClick = () => {
        if (!hasBillingData) {
            setShowBillingInfoModal(true)
        } else {
            sendMegaGift({hostID: `${hostID}`})
        }
    }

    useEffect(() => {
        if (errorSendMegaGift && errorSendMegaGift.response) {
            if (errorSendMegaGift.response.status === 303 && !isLoadingSendMegaGift) {
                openChoosePaymentsMethodModal({
                    paymentMethodUrls: errorSendMegaGift.response.data,
                    currencyAmount: 500,
                    morgisAmount: 5000,
                    description: 'choose_payment_method:description_micromorgi',
                    apiCallback: () => {
                        sendMegaGift({hostID: `${hostID}`})
                        closeChosePaymentMethodModal()
                    },
                    isMegaGift: true
                })
            } else openErrorModal({message: errorSendMegaGift.message})
        }
        if (dataSendMegaGift) {
            isOpenSendMegaGiftModal && closeSendMegaGiftModal()
            isOpenPaidGifsModal && closePaidGifsModal()
            onSuccessSendGift?.()
            openSuccessMegaGiftOverlay({})
            mixpanel?.trackEvent(MixpanelEventNameE.MegaGiftSent)
        }
    }, [errorSendMegaGift, dataSendMegaGift])

    return (
        <>
            <Modal
                maxWidth="392px"
                minHeight="550px"
                title={t('sent_mega_gift_modal:title')}
                onClose={closeSendMegaGiftModal}
            >
                {isLoadingSendMegaGift && <Spinner />}
                <StyledSendMegaGiftModalContainer>
                    <StyledSendMegaGiftModalWrapper>
                        <StyledSendMegaGiftModalImage src={megaGift} />
                        <StyledSendMegaGiftModalDescription>
                            {t(`sent_mega_gift_modal:subtitle`)}
                            <StyledSendMegaGiftModalDescriptionCost>
                                <MicromorgiIcon height={14} width={14} />
                                {MEGA_GIFT.cost}
                            </StyledSendMegaGiftModalDescriptionCost>
                        </StyledSendMegaGiftModalDescription>
                        <StyledSendMegaGiftModalSendButton
                            onClick={onSendMegaGiftClick}
                        >
                            {t(`sent_mega_gift_modal:cta_text`)}
                        </StyledSendMegaGiftModalSendButton>
                    </StyledSendMegaGiftModalWrapper>
                </StyledSendMegaGiftModalContainer>
            </Modal>
            {showBillingInfoModal && <BillingInfoModal onClose={() => setShowBillingInfoModal(false)} onSendBillingInfoSuccess={()=>sendMegaGift({hostID: `${hostID}`})} />}
        </>
    )
}
