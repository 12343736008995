import {MicromorgiIconMotion} from '@/assets/icons/icons'
import {Modal} from '@/components/ui/modal/Modal'
import {useMe} from '@/features/authentication/queries/useMe'
import useSendChatMessage from '@/features/chat/hooks/useSendChatMessage'
import {ChannelID} from '@/features/chat/types'
import {useDonateGoal} from '@/features/goal/queries/useDonateGoal'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useRootStore} from '@/store'
import {selectChatChannels, selectConfirmSendGiftModal, selectPaidGifsModal} from '@/store/selectors'
import {MessageTypeE} from '@/utilities/constants/chat'
import {generateChatChannelName, getCorrectUserId} from '@/utilities/helpers'
import {AnimatePresence, motion} from 'framer-motion'
import {FC, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {PulseAnimation} from '../animations/pulse-animation/PulseAnimation'
import {useGetCurrentPaidGif} from '../queries/useGetCurrentPaidGif'
import {useSendMicromorgi} from '../queries/useSendMicromorgi'
import {
    StyledAmount,
    StyledAnimation,
    StyledConfirmButton,
    StyledConfirmSendGiftModalWrapper,
    StyledConfirmText,
    StyledPulseWrapper
} from './styles'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/features/chat/queries/keys'
import {TypeOfConfirmModalE} from './store/createConfirmSendGiftModalSlice'
import {useIsMutating} from '@tanstack/react-query'
import {
    MUTATION_KEYS as UNLOCK_TEXT_MUTATION_KEYS,
    QUERY_KEYS as UNLOCK_PAID_MEDIA_QUERY_KEYS
} from '@/features/chat/queries/keys.ts'

const transitionDuration = 1.5
const modalVariants = {
    initial: {
        opacity: 0,
        x: '-50%',
        y: '-40%'
    },
    animate: {
        x: '-50%',
        y: '-50%',
        opacity: 1,
        transition: {duration: transitionDuration}
    }
}

export const ConfirmSendGiftModal: FC = () => {
    const {t} = useTranslation()
    const {closeModal: closeGifsModal} = useRootStore(selectPaidGifsModal)
    const {
        confirmText,
        onConfirmCallBack,
        amount,
        animationID = 0,
        hostID,
        goalId,
        closeModal,
        setStatus,
        typeOfModal
    } = useRootStore(selectConfirmSendGiftModal)
    const queryClient = useQueryClient()

    const onSendMicromoriSuccess = () => {
        closeModal()
        closeGifsModal()
        if (typeOfModal && [(TypeOfConfirmModalE.openConnection, TypeOfConfirmModalE.giftList)].includes(typeOfModal)) {
            queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.fetchChannelDetails]
            })
            queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.fetchChannels]
            })
        }
    }

    const mixpanel = useMixPanel()
    const {mutateAsync: sendMicromorgi, isPending: isPendingSendMicromorgi} = useSendMicromorgi(onSendMicromoriSuccess)
    const {mutateAsync: donateGoal, isPending: isPendingDonateGoal} = useDonateGoal(`${goalId}`, status => {
        setStatus(status)
        closeModal()
    })
    const {data: animationIcon} = useGetCurrentPaidGif({animation_id: animationID})
    const {data: user} = useMe()
    const channel = user && hostID ? generateChatChannelName(user?.id, user?.type, Number(hostID)) : ''
    const {data: host} = useGetHost({filter: hostID ?? ''})
    const channels = useRootStore(selectChatChannels)

    const isChannelCreated = !!channel && !!channels[channel]

    const {sendChatMessage} = useSendChatMessage(channel as ChannelID)
    const isPendingUnlockTextMessage = useIsMutating({mutationKey: [UNLOCK_TEXT_MUTATION_KEYS.unlockTextMessage]})
    const isPendingUnlockMediaMessage = useIsMutating({
        mutationKey: [UNLOCK_PAID_MEDIA_QUERY_KEYS.getUnlockedMediaMessage]
    })

    const onClickSend = useCallback(async () => {
        if (amount && hostID && user) {
            if (goalId) {
                await donateGoal({hostID, amount})
                await sendChatMessage({
                    type: MessageTypeE.TRANSACTION_GOAL,
                    userId: getCorrectUserId(user),
                    goalId: Number(goalId),
                    micromorgiAmount: amount,
                    needReInitChatBeforeSendToPubNub: !isChannelCreated
                })
            } else {
                await sendMicromorgi({
                    amount,
                    hostID,
                    animation_id: animationID
                })

                if (animationID) {
                    sendChatMessage({
                        type: MessageTypeE.GIFT_TRANSACTION,
                        userId: getCorrectUserId(user),
                        animationId: animationID,
                        micromorgiAmount: amount
                    })
                    mixpanel?.trackEvent(MixpanelEventNameE.AnimationSend, {
                        animation: animationID,
                        morgi_amount: amount,
                        guest_id: user.id
                    })
                } else {
                    sendChatMessage({
                        type: MessageTypeE.MICROMORGI_TRANSACTION,
                        userId: getCorrectUserId(user),
                        micromorgiAmount: amount
                    })
                }
            }
            closeModal()
        }
    }, [amount, hostID, closeModal, donateGoal, sendMicromorgi, user, goalId, isChannelCreated])

    return (
        <Modal onClose={closeModal} width={'300px'} animation={modalVariants} id="id-of-confirm-modal">
            {animationID && typeOfModal === TypeOfConfirmModalE.giftList ? (
                <StyledAnimation src={animationIcon?.url} />
            ) : (
                <StyledPulseWrapper>
                    <PulseAnimation duration={transitionDuration} />
                    {typeOfModal !== TypeOfConfirmModalE.giftList && (
                        <StaticMotionPulseMicromorgiIcon iconSize={{height: 82, width: 59}} />
                    )}
                </StyledPulseWrapper>
            )}

            <StyledConfirmSendGiftModalWrapper>
                {amount && <StyledAmount>{`+${amount}`}</StyledAmount>}
                <StyledConfirmText>
                    {confirmText ??
                        t('chat:send_user_micromorgi?', {
                            USER: host?.full_name
                        })}
                </StyledConfirmText>
                <StyledConfirmButton
                    disabled={
                        isPendingSendMicromorgi ||
                        isPendingDonateGoal ||
                        !!isPendingUnlockTextMessage ||
                        !!isPendingUnlockMediaMessage
                    }
                    variant="secondary"
                    onClick={typeof onConfirmCallBack === 'function' ? onConfirmCallBack : onClickSend}
                >
                    {t('common:confirm')}
                </StyledConfirmButton>
            </StyledConfirmSendGiftModalWrapper>
        </Modal>
    )
}

const defaultWidth = 59
const defaultHeight = 82

type StaticMotionPulseMicromorgiIconProps = {
    iconSize: {width: number; height: number}
}
const StaticMotionPulseMicromorgiIcon: FC<StaticMotionPulseMicromorgiIconProps> = ({iconSize}) => {
    const getScale = (scaleValue: number) =>
        `${(defaultWidth / iconSize.width) * scaleValue}, ${(defaultHeight / iconSize.height) * scaleValue}`

    return (
        <AnimatePresence>
            <motion.div
                initial={{
                    position: 'absolute'
                }}
                animate={{
                    scale: [
                        getScale(1),
                        getScale(1),
                        getScale(0.8),
                        getScale(1),
                        getScale(0.8),
                        getScale(1),
                        getScale(0.8),
                        getScale(1)
                    ],
                    transition: {duration: 6},
                    pointerEvents: 'none',
                    ...iconSize
                }}
            >
                <MicromorgiIconMotion {...iconSize} />
            </motion.div>
        </AnimatePresence>
    )
}
