import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

type SessionExpireTimerProps = {
    minutes: number
    onComplete?: () => void
    isReInitTimer?: boolean
}

export const SessionExpireTimer: FC<SessionExpireTimerProps> = ({minutes, onComplete, isReInitTimer = false}) => {
    const {t} = useTranslation()
    const [timeLeft, setTimeLeft] = useState<number>(minutes * 60)
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(prevTimeLeft => {
                if (prevTimeLeft < 1) {
                    onComplete?.()
                    if (isReInitTimer) return minutes * 60
                    clearInterval(interval)
                    return 0
                }
                return prevTimeLeft - 1
            })
        }, 1000)

        return () => clearInterval(interval)
    }, [minutes, onComplete])

    const formatTime = (seconds: number) => {
        const m = Math.floor(seconds / 60)
        const s = seconds % 60
        return `${t('session_expire_timer:session_will_expire_after')} ${m}:${s < 10 ? '0' : ''}${s}`
    }

    return <h1>{formatTime(timeLeft)}</h1>
}
