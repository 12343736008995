import {useMe} from '@/features/authentication/queries/useMe.ts'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors'
import {UserRoleE} from '@/types.ts'
import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'
import {CHAT_API} from '../services/chat.http'
import {QUERY_KEYS} from './keys'
import {RepresentativeType} from '@/features/authentication/types'
import {USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user'
import {getCorrectUserId} from '@/utilities/helpers'
import { LocalStorageManager } from '@/utilities/localStorage'

export const useGetChannels = () => {
    const {data: user, loggedIn} = useMe()
    const {setChannels} = useRootStore(selectChatStore)
    const userRepresentative = user as RepresentativeType
    const isRepresentative = user?.type === USER_ROLE_REPRESENTATIVE
    const correctUserId = user ? getCorrectUserId(user) : undefined
    const correctUserType = isRepresentative ? userRepresentative.type_attributes.represented_user.type : user?.type

    // TODO: this should be useInfiniteQuery
    const query = useQuery({
        queryKey: [QUERY_KEYS.fetchChannels],
        queryFn: CHAT_API.getChannels,
        enabled: !!user && !!loggedIn && !!LocalStorageManager.loginUser.get()
    })

    useEffect(() => {
        if (query.data && user && correctUserId) {
            setChannels(query.data, {id: correctUserId, role: correctUserType as UserRoleE})
        }
    }, [query.data, user, correctUserId])
    // set the store data and return it
    return query
}

// const channels = useChatStore(store => store.channels)
