import {Animation} from '@/components/ui/animation/Animation'
import {CashbackBanner} from '@/components/ui/cashback-banner/CashbackBanner'
import {Modal} from '@/components/ui/modal/Modal'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {GenderKeyNameE} from '@/features/constants/constants'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import {ShareProfileModal} from '@/features/host/components/share-profile-modal/ShareProfileModal'
import {remapCurrency} from '@/features/profile/constants'
import {useSendGift} from '@/features/recurring-gift/queries/useSendGift'
import {MEGA_GIFT} from '@/featuresConfig'
import useRedirectToChatChannel from '@/hooks/useRedirectToChatChannel'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useRootStore} from '@/store'
import {
    selectChoosePaymentsMethodModal,
    selectErrorModal,
    selectRecurringGiftModal,
    selectSendMegaGiftModal
} from '@/store/selectors'
import {UserRoleE} from '@/types'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import successGiftedAnimation from '@assets/animations/Success-Gifted.json'
import {chunk} from 'lodash'
import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {AmountItem} from '../../gift/components/amount-item/AmountItem'
import {useFetchSubscribedAmounts} from '../queries/useFetchSubscribedAmounts'
import {useFetchSubscribedHosts} from '../queries/useFetchSubscribedHosts'
import {Donation, DonationType} from '../types'
import {
    StyledAmountListToggle,
    StyledAmountsList,
    StyledAnimation,
    StyledBannerWrapper,
    StyledContentTitle,
    StyledDeclaimer,
    StyledFooter,
    StyledGiftModalStepContainer,
    StyledModalTitle,
    StyledNextPageButton,
    StyledRedirectToChatButton,
    StyledShareButton,
    StyledSuccessBox,
    StyledSuccessButtonBox,
    StyledSuccessContainer,
    StyledSuccessSubTitle,
    StyledSuccessTitle,
    StyledSuccessTitleBox,
    modalStyles
} from './style'
import {SubscriptionStatusesE} from '@/features/chat/helpers/helpers'
import {TagManager} from '@/integrations/tag-manager/tagManager'
import {useLocation, useNavigate} from 'react-router-dom'
import {BillingInfoModal} from '@/features/billing/components/billing-info-modal/BillingInfoModal'
import {ROUTE_UPDATE_CREDIT_CARD} from '@/utilities/constants/routeNames'
import {LocalStorageManager} from '@/utilities/localStorage'

type GiftModalStepProps = {
    changeStep: (step: 1 | 2 | 3) => void
}

const GIFT_MODAL_STEPS_MAP = {
    1: (props: GiftModalStepProps) => <GiftModalChangeAmount {...props} />,
    2: (props: GiftModalStepProps) => <GiftModalAddCard {...props} />,
    3: (props: GiftModalStepProps) => <GiftModalSuccess {...props} />
}
export const MEGA_GIFT_ID = 'mega-gift'

export const GiftModal: FC = () => {
    const {t} = useTranslation()
    const {closeModal: closeGiftModal, hostID} = useRootStore(selectRecurringGiftModal)
    const {data: host} = useGetHost({filter: `${hostID}`})

    const [step, setPage] = useState<1 | 2 | 3>(1)

    return (
        <Modal
            showConfettiAnimation
            onClose={() => {
                closeGiftModal()
            }}
            fullScreen
            height={'550px'}
            maxHeight={'600px'}
            width={'400px'}
            title={
                <StyledModalTitle>
                    {t('gift_rookie:title_send_gift', {
                        USER: host?.type_attributes.first_name
                    })}
                </StyledModalTitle>
            }
            modalStyles={modalStyles}
        >
            {GIFT_MODAL_STEPS_MAP[step]?.({
                changeStep: setPage
            })}
        </Modal>
    )
}

const GiftModalChangeAmount: FC<GiftModalStepProps> = ({changeStep}) => {
    const amountsListRef = useRef<HTMLDivElement>(null)
    const {data} = useMe()
    const user = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null
    const currencySymbol = remapCurrency[user?.currency || 'USD']
    const navigate = useNavigate()
    const location = useLocation()
    const redirectUrl = location.pathname

    const {
        hostID,
        closeModal: closeGiftModal,
        updateModalDate,
        minAmountToOpenConnection,
        selectedAmount
    } = useRootStore(selectRecurringGiftModal)
    const {data: host} = useGetHost({filter: `${hostID}`})
    const {openModal: openChoosePaymentsMethodModal} = useRootStore(selectChoosePaymentsMethodModal)
    const {openModal: openSendMegaGiftModal} = useRootStore(selectSendMegaGiftModal)
    const {data: amountsList, isLoading} = useFetchSubscribedAmounts(false)
    const {data: subscriptionHosts} = useFetchSubscribedHosts({
        enabled: true,
        status: SubscriptionStatusesE.ACTIVE
    })

    const [amountsListWithMegaGift, setAmountsListWithMegaGift] = useState<DonationType[]>([])

    const activeSubscription = subscriptionHosts?.find(
        (subsriptionsHost: Donation) => subsriptionsHost.rookie_id.toString() === hostID
    )
    const {mutate: sendGift, data: sendGiftResponse, error: sendGiftError, isPending: isPendingSendGift} = useSendGift()
    const {openErrorModal} = useRootStore(selectErrorModal)
    const [isShowFullList, setIsShowFullList] = useState(false)
    const hasBillingData = !!user?.type_attributes?.has_invoicing_profile
    const [showBillingInfoModal, setShowBillingInfoModal] = useState(false)

    const {t} = useTranslation()
    const onSuccess = () => {
        if (!hasBillingData) {
            setShowBillingInfoModal(true)
        } else {
            TagManager.dataLayer({
                event: 'giftmenu_pop_click_redirect'
            })
            user && hostID && sendGift({hostID, subscriptionId: `${selectedAmount?.id}`})
        }
    }
    const mixpanel = useMixPanel()
    const eventData = {
        eventType: MixpanelEventNameE.GiftMorgiClick,
        userRole: UserRoleE.Guest,
        userId: `${hostID}`
    }
    useEffect(() => {
        LocalStorageManager.openGiftModal.remove()
        mixpanel?.triggerBackendEvent({...eventData})
        mixpanel?.startTimeEvent(MixpanelEventNameE.CheckoutContinue)

        TagManager.dataLayer({
            event: 'giftmenu_pop_view',
            email: user?.email,
            fullName: user?.full_name,
            userName: user?.username,
            userId: user?.id,
            country: user?.signup_country?.alpha_2_code ?? user?.signup_country.id,
            currency: user?.currency,
            micromorgiBalance: user?.type_attributes.micro_morgi_balance,
            userType: user?.type
        })
    }, [])
    useEffect(() => {
        amountsListRef.current?.scrollTo?.({
            top: isShowFullList ? 1000 : 0,
            behavior: 'smooth'
        })
    }, [isShowFullList, amountsListRef])

    useEffect(() => {
        if (sendGiftError) {
            if (sendGiftError.response?.status === 303) {
                openChoosePaymentsMethodModal({
                    apiCallback: onSuccess,
                    paymentMethodUrls: sendGiftError.response?.data?.url ?? null,
                    description: 'choose_payment_method:description_gift',
                    currencyAmount: selectedAmount?.dollar_amount,
                    rookieName: host?.type_attributes.first_name,
                    isMonthlySupport: true
                })
                mixpanel?.trackEvent(MixpanelEventNameE.CheckoutContinue)
            } else {
                closeGiftModal()
                openErrorModal({message: sendGiftError.message || null})
            }
        }
        if (sendGiftResponse) {
            changeStep(3)
        }
    }, [sendGiftResponse, sendGiftError])

    useEffect(() => {
        if (amountsList) {
            const _amountsList = [...amountsList]
            _amountsList.splice(_amountsList?.findIndex(({is_locked}) => is_locked) - 1, 0, {
                id: MEGA_GIFT_ID,
                amount: MEGA_GIFT.cost,
                dollar_amount: MEGA_GIFT.dollarAmount,
                is_default: false,
                is_locked: false,
                is_visible: true
            })

            setAmountsListWithMegaGift(_amountsList)
        }
    }, [amountsList])

    const onSelectGift = (selectedAmount: DonationType) => {
        mixpanel?.trackEvent(MixpanelEventNameE.GiftAmountClick, {
            amountSelected: selectedAmount.amount
        })
        if (selectedAmount.id === MEGA_GIFT_ID) {
            openSendMegaGiftModal({hostID})
            closeGiftModal()
            return
        }

        updateModalDate({selectedAmount})
    }

    if (isPendingSendGift) return <Spinner />

    return (
        <StyledGiftModalStepContainer>
            <div>
                {isLoading && <Spinner />}
                <StyledContentTitle>{t('gift_rookie:subtitle_send_gift')}</StyledContentTitle>
            </div>

            <StyledBannerWrapper>
                <CashbackBanner />
            </StyledBannerWrapper>

            <StyledAmountsList ref={amountsListRef}>
                {(isShowFullList ? amountsListWithMegaGift : chunk(amountsListWithMegaGift, 5)[0])?.map(amount => {
                    return (
                        (!amount.is_locked || isShowFullList) && (
                            <AmountItem
                                disabledByMinAmount={
                                    !!minAmountToOpenConnection && amount.amount < minAmountToOpenConnection
                                }
                                key={amount.id}
                                amount={amount}
                                onSelect={() => {
                                    activeSubscription?.amount !== amount?.amount && onSelectGift(amount)
                                }}
                                modalContainer={amountsListRef?.current}
                                isSelected={selectedAmount?.id === amount.id}
                                activeSubscriptionAmount={activeSubscription?.amount}
                            />
                        )
                    )
                })}
            </StyledAmountsList>

            <StyledFooter>
                <StyledAmountListToggle
                    onClick={() => {
                        setIsShowFullList(prev => !prev)
                    }}
                >
                    {t(`gift_rookie:${isShowFullList ? 'show_less_amounts' : 'show_more_amounts'}`)}
                </StyledAmountListToggle>
                <StyledNextPageButton
                    variant="primary"
                    onClick={onSuccess}
                    disabled={
                        isPendingSendGift || !selectedAmount || selectedAmount.amount === activeSubscription?.amount
                    }
                >
                    <Trans
                        t={t}
                        i18nKey="send_custom_amount:send_N_micromorgies"
                        values={{
                            AMOUNT: selectedAmount?.amount
                        }}
                    />
                </StyledNextPageButton>
                {selectedAmount && (
                    <StyledDeclaimer>
                        {!user?.type_attributes?.preferred_payment_method?.last4 ? (
                            t('gift_rookie:disclaimer:new_transaction', {
                                CURRENCY: currencySymbol,
                                AMOUNT: selectedAmount.dollar_amount
                            })
                        ) : (
                            <Trans
                                i18nKey={'gift_rookie:disclaimer:change_payment_method'}
                                values={{
                                    LAST_4: user.type_attributes.preferred_payment_method.last4
                                }}
                                components={{
                                    span: (
                                        <span
                                            onClick={() => {
                                                navigate(ROUTE_UPDATE_CREDIT_CARD)
                                                !!hostID &&
                                                    LocalStorageManager.openGiftModal.set({redirectUrl, hostId: hostID})
                                                closeGiftModal()
                                            }}
                                        ></span>
                                    )
                                }}
                            />
                        )}
                    </StyledDeclaimer>
                )}
            </StyledFooter>
            {showBillingInfoModal && (
                <BillingInfoModal
                    onClose={() => setShowBillingInfoModal(false)}
                    onSendBillingInfoSuccess={() =>
                        user && hostID && sendGift({hostID, subscriptionId: `${selectedAmount?.id}`})
                    }
                />
            )}
        </StyledGiftModalStepContainer>
    )
}

const GiftModalAddCard: FC<GiftModalStepProps> = () => {
    return <StyledGiftModalStepContainer></StyledGiftModalStepContainer>
}

const GiftModalSuccess: FC<GiftModalStepProps> = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const {selectedAmount, hostID, closeModal: closeRecurringGiftModal} = useRootStore(selectRecurringGiftModal)
    const {data: user} = useMe()
    const {data: host} = useGetHost({filter: `${hostID}`})
    const redirectToChatChannel = useRedirectToChatChannel({receiverId: parseInt(`${hostID}`)})
    const [isOpenShareProfileModal, setIsOpenShareProfileModal] = useState(false)

    const hostGender = useMemo(() => {
        if (host) {
            switch (host?.gender?.id) {
                case 1:
                    return GenderKeyNameE.MALE
                case 2:
                    return GenderKeyNameE.FEMALE
                default:
                    return GenderKeyNameE.OTHER
            }
        }
    }, [host])

    useEffect(() => {
        TagManager.dataLayer({
            event: 'giftThankYouPage',
            currentUrl: location?.pathname
        })
    }, [])

    return (
        <StyledGiftModalStepContainer>
            <StyledBannerWrapper>
                <CashbackBanner />
            </StyledBannerWrapper>
            <StyledSuccessContainer>
                <StyledSuccessBox>
                    <StyledAnimation>
                        <Animation loop autoplay={true} animationData={successGiftedAnimation} />
                    </StyledAnimation>
                    <div>
                        <StyledSuccessTitleBox>
                            <StyledSuccessTitle>
                                {t('gift_rookie:success:title', {
                                    Morgis: selectedAmount?.amount,
                                    USER: host?.full_name
                                })}
                            </StyledSuccessTitle>
                        </StyledSuccessTitleBox>
                        <StyledSuccessTitleBox>
                            <StyledSuccessSubTitle>
                                {t('gift_rookie:success:description', {
                                    USER: host?.full_name
                                })}
                            </StyledSuccessSubTitle>
                        </StyledSuccessTitleBox>
                    </div>
                </StyledSuccessBox>
                <StyledSuccessButtonBox>
                    <StyledRedirectToChatButton
                        onClick={() => {
                            if (user && hostID) {
                                redirectToChatChannel?.()
                                closeRecurringGiftModal()
                            }
                        }}
                    >
                        {t('gift_rookie:go_to_chat')}
                    </StyledRedirectToChatButton>
                    <StyledShareButton onClick={() => setIsOpenShareProfileModal(true)} variant="outlined">
                        {t('gift_rookie:share_with_friends')}
                    </StyledShareButton>
                </StyledSuccessButtonBox>
            </StyledSuccessContainer>

            {isOpenShareProfileModal && (
                <ShareProfileModal
                    hostGender={hostGender}
                    username={`${host?.username}`}
                    onClose={() => setIsOpenShareProfileModal(false)}
                    type="gift"
                />
            )}
        </StyledGiftModalStepContainer>
    )
}
