import React, {useState} from 'react'

import {useTranslation} from 'react-i18next'
import {StyledBillingInfo, StyledCustomerBillingInfoSection, StyledEditButton} from './style'
import {useMe} from '@/features/authentication/queries/useMe'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {GuestType} from '@/features/authentication/types'
import {useGetBillingInfo} from '@/features/billing/queries/useGetBillingInfo'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useHandleError} from '@/hooks/useHandleError'
import {BillingInfoModal} from '@/features/billing/components/billing-info-modal/BillingInfoModal'

type CustomerBillingInfoSectionProps = {
    onSendBillingInfoSuccess?: () => void
}

const CustomerBillingInfoSection: React.FC<CustomerBillingInfoSectionProps> = ({onSendBillingInfoSuccess}) => {
    const {t} = useTranslation()
    const {data} = useMe()
    const user = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null
    const {data: billingInfo, isLoading, error, isError} = useGetBillingInfo(Number(user?.id))
    const [showBillingInfoModal, setShowBillingInfoModal] = useState(false)

    useHandleError({isError, error})

    const onSuccess = () => {
        onSendBillingInfoSuccess?.()
        setShowBillingInfoModal(false)
    }

    return (
        <>
            {isLoading && <Spinner />}
            <StyledCustomerBillingInfoSection>
                <StyledBillingInfo>
                    {t('choose_payment_method:customer_billing_info')} {billingInfo?.first_name}{' '}
                    {billingInfo?.last_name}, {billingInfo?.country_name}, {billingInfo?.city}
                </StyledBillingInfo>

                <StyledEditButton onClick={() => setShowBillingInfoModal(true)}>{t('common:edit')}</StyledEditButton>
            </StyledCustomerBillingInfoSection>

            {showBillingInfoModal && (
                <BillingInfoModal onClose={() => setShowBillingInfoModal(false)} onSendBillingInfoSuccess={onSuccess} />
            )}
        </>
    )
}

export default CustomerBillingInfoSection
