import {InputText} from '@/components/commons/input-text/InputText'
import {useMapsLibrary} from '@vis.gl/react-google-maps'
import {FC, FocusEvent, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Address} from '../auto-complete-address/AutoCompleteAddress'

type PlaceAutocompleteProps = {
    onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
    onBlur: (e: FocusEvent<HTMLInputElement, Element>) => void
    error?: string
    name: string
    defaultValue?: Address
}

export const PlaceAutocomplete: FC<PlaceAutocompleteProps> = ({onPlaceSelect, onBlur, defaultValue, error, name}) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const places = useMapsLibrary('places')
    const {t} = useTranslation()
    useEffect(() => {
        if (!places || !inputRef.current) return

        const options = {
            fields: ['address_components']
        }

        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, {...options}))
    }, [places])

    useEffect(() => {
        if (!placeAutocomplete) return

        placeAutocomplete.addListener('place_changed', () => {
            onPlaceSelect({...placeAutocomplete.getPlace(), formatted_address: inputRef.current?.value})
        })
    }, [onPlaceSelect, placeAutocomplete])

    useEffect(() => {
        if (inputRef.current && defaultValue) {
            onPlaceSelect({
                formatted_address: defaultValue.raw_address,
                address_components: [
                    {long_name: defaultValue.city, short_name: defaultValue.city, types: ['locality']},
                    {long_name: defaultValue.zip_code, short_name: defaultValue.zip_code, types: ['postal_code']},
                    {
                        long_name: defaultValue.country_name,
                        short_name: defaultValue?.country_code,
                        types: ['country']
                    },
                    {
                        long_name: defaultValue.state_code,
                        short_name: defaultValue.state_code,
                        types: ['administrative_area_level_1']
                    }
                ]
            })
            inputRef.current.value = defaultValue.raw_address as string
        }
    }, [defaultValue])

    return (
        <>
            <InputText
                ref={inputRef}
                placeholder={t('set_billing_info:address_placeholder')}
                name={name}
                errorMessage={error ? t(error as string) : undefined}
                onChange={e => {
                    if (!e.target.value.length) {
                        setPlaceAutocomplete(null)
                        onPlaceSelect(null)
                    }
                }}
                onBlur={onBlur}
            />
        </>
    )
}
