import React from 'react'
import {StyledCardRoot} from './stype'
type PaymentPlatformCardProps = {
    icon: string
    url?: string
}

const PaymentPlatformCard: React.FC<PaymentPlatformCardProps> = ({icon, url}) => {
    const onPlatformCardClick = (url: string) => {
        window.open(url, '_blank')
    }

    return (
        <StyledCardRoot onClick={() => onPlatformCardClick(`${url}`)}>
            <img alt={'goal logo'} src={icon} />
        </StyledCardRoot>
    )
}

export default PaymentPlatformCard
