import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from './keys'
import {CHAT_API} from '../services/chat.http'

export const useSetLastReadTimetoken = (channel: string) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.setLastReadTimetoken],
        mutationFn: ({timetoken, channelID}: {channelID: string; timetoken: string}) =>
            CHAT_API.setLastReadTimetoken(channelID, timetoken),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.fetchChannelDetails, channel]})
        }
    })
}
