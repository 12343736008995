import {Button} from '@/components/ui/button/Button'
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {zodResolver} from '@hookform/resolvers/zod'
import {useMe} from '@/features/authentication/queries/useMe'
import {BillingInfoResponseType, SendBillingInfoPayloadType, SendBillingValidationType} from '../../types'
import {BILLING_INFO_MODEL, SendBillingValidationSchema} from '../../services/billing.schema'
import {InputText} from '@/components/commons/input-text/InputText'
import {StyledDescription, StyledFormRoot} from './style'
import {useSendBillingInfo} from '../../queries/useSendBillingInfo'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useHandleError} from '@/hooks/useHandleError'
import {AutoCompleteAddressController} from '../auto-complete-address-controller/AutoCompleteAddressController'
import {useRootStore} from '@/store'
import {selectAlertsStore} from '@/store/selectors'
import {useEditBillingInfo} from '../../queries/useEditBillingInfo'
import {AppStatusesE} from '@/utilities/constants/appStatus'
import {GuestType} from '@/features/authentication/types'
import {isEqual} from 'lodash'

const BillingInfoForm = ({
    defaultBillingInfo,
    onSendBillingInfoSuccess
}: {
    defaultBillingInfo?: BillingInfoResponseType
    onSendBillingInfoSuccess?: () => void
}) => {
    const {t} = useTranslation()
    const {addAlert} = useRootStore(selectAlertsStore)
    const {data} = useMe()
    const user = data as GuestType
    const hasInvoicingSettings = user?.type_attributes?.has_invoicing_profile
    const defaultFormData: SendBillingValidationType = {
        [BILLING_INFO_MODEL.Email]: user?.email,
        [BILLING_INFO_MODEL.Phone]: defaultBillingInfo?.phone_number ?? '',
        [BILLING_INFO_MODEL.CostumerName]: defaultBillingInfo
            ? `${defaultBillingInfo?.first_name} ${defaultBillingInfo?.last_name}`
            : '',
        [BILLING_INFO_MODEL.Street]: defaultBillingInfo
            ? {
                  [BILLING_INFO_MODEL.Address]: defaultBillingInfo?.address,
                  [BILLING_INFO_MODEL.City]: defaultBillingInfo?.city,
                  [BILLING_INFO_MODEL.ZipCode]: defaultBillingInfo?.zip_code,
                  [BILLING_INFO_MODEL.RowAddress]: defaultBillingInfo?.raw_address,
                  [BILLING_INFO_MODEL.CountryName]: defaultBillingInfo?.country_name || '',
                  [BILLING_INFO_MODEL.CountryCode]: defaultBillingInfo?.country_code || '',
                  [BILLING_INFO_MODEL.StateCode]: defaultBillingInfo?.state_code || ''
              }
            : undefined
    }
    const methods = useForm<SendBillingValidationType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: zodResolver(SendBillingValidationSchema),
        defaultValues: defaultFormData
    })

    const {
        handleSubmit,
        formState: {errors, touchedFields},
        register,
        setError,
        getValues
    } = methods

    const isValid = !Object.keys(errors).length
    const onSuccess = () => {
        onSendBillingInfoSuccess?.()
        addAlert({
            message: !hasInvoicingSettings ? t('set_billing_info:send_success') : t('set_billing_info:edit_success'),
            variant: AppStatusesE.SUCCESS,
            dismissTimeout: 5000
        })
    }

    const {
        mutate: sendBillingInfo,
        isPending: isSendBillingInfoPending,
        error: sendBillingInfoError,
        isError: isSendBillingInfoError
    } = useSendBillingInfo({onSuccess})

    const {
        mutate: editBillingInfo,
        isPending: isEditBillingInfoPending,
        error: editBillingInfoError,
        isError: isEditBillingInfoError
    } = useEditBillingInfo({onSuccess})

    useHandleError({
        isError: isSendBillingInfoError || isEditBillingInfoError,
        error: sendBillingInfoError || editBillingInfoError,
        setError
    })

    const onSubmit: SubmitHandler<SendBillingInfoPayloadType> = body => {
        if (body.street && !body.street[BILLING_INFO_MODEL.ZipCode]) {
            Object.assign(body, {
                ...body,
                street: {...body.street, [BILLING_INFO_MODEL.ZipCode]: '0000'}
            })
        }

        !hasInvoicingSettings
            ? sendBillingInfo({body, guestId: Number(user?.id)})
            : editBillingInfo({body, guestId: Number(user?.id)})
    }

    return (
        <FormProvider {...methods}>
            <StyledFormRoot onSubmit={handleSubmit(onSubmit)}>
                {(isSendBillingInfoPending || isEditBillingInfoPending) && <Spinner />}
                {/*Costumer name*/}

                <>
                    <InputText
                        type={'text'}
                        label={`${t('common:customer_name')}*`}
                        placeholder={t('set_billing_info:customer_name_placeholder')}
                        touched={touchedFields[BILLING_INFO_MODEL.CostumerName]}
                        errorMessage={t(errors[BILLING_INFO_MODEL.CostumerName]?.message || '')}
                        {...register(BILLING_INFO_MODEL.CostumerName)}
                    />

                    {/* Email */}
                    <InputText
                        type={'email'}
                        label={`${t('common:email')}*`}
                        placeholder={t('set_billing_info:email_placeholder')}
                        touched={touchedFields[BILLING_INFO_MODEL.Email]}
                        errorMessage={`${t(errors[BILLING_INFO_MODEL.Email]?.message || '')}`}
                        {...register(BILLING_INFO_MODEL.Email)}
                    />

                    {/* Phone */}
                    <InputText
                        type={'text'}
                        label={`${t('common:phone')}*`}
                        placeholder={t('set_billing_info:phone_placeholder')}
                        touched={touchedFields[BILLING_INFO_MODEL.Phone]}
                        errorMessage={t(errors[BILLING_INFO_MODEL.Phone]?.message || '')}
                        {...register(BILLING_INFO_MODEL.Phone)}
                    />

                    {/* Street */}
                    <AutoCompleteAddressController
                        name={BILLING_INFO_MODEL.Street}
                        label={`${t('common:street')}*`}
                        innerFields={[
                            BILLING_INFO_MODEL.Address,
                            BILLING_INFO_MODEL.City,
                            BILLING_INFO_MODEL.CountryCode,
                            BILLING_INFO_MODEL.CountryName,
                            BILLING_INFO_MODEL.RowAddress,
                            BILLING_INFO_MODEL.StateCode,
                            BILLING_INFO_MODEL.ZipCode
                        ]}
                    />

                    <StyledDescription>{t('set_billing_info:page_support_description')}</StyledDescription>
                    <Button type="submit" fullWidth disabled={!(isValid && !isEqual(defaultFormData, getValues()))}>
                        {t('common:save')}
                    </Button>
                </>
            </StyledFormRoot>
        </FormProvider>
    )
}

export default BillingInfoForm
