import styled, {css} from 'styled-components'

export const StyledCustomerBillingInfoSection = styled.div`
    ${({theme: {typography}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${typography.textXs}
    `}
`

export const StyledBillingInfo = styled.div`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral.black};
    `}
`

export const StyledEditButton = styled.div`
    ${({theme: {palette}}) => css`
        text-decoration: underline;
        color: ${palette.primary[325]};
        cursor: pointer;
    `}
`
