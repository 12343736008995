import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from './keys'
import {FEED_API} from '../services/feed.http'
import {LocalStorageManager} from '@/utilities/localStorage'

export const useSendSeenTalents = (onSuccess?: (id: number) => void) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.send_seen_talents],
        mutationFn: (dataToSend: {rookiesIDs: number[]}) => {
            return FEED_API.seenTalents(dataToSend)
        },
        onSuccess: data => {
            const firstTalentId = +`${data?.data[0]?.id}`
            LocalStorageManager.seenTalentIds.set([firstTalentId])
            onSuccess?.(firstTalentId)
        }
    })
}
