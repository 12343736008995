import {RedirectIcon} from '@/assets/icons/icons'
import {SessionExpireTimer} from '@/features/billing/components/session-expire-timer/SessionExpireTimer'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {FC, Fragment, useCallback, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {CreditCardsNameE, CreditCardTypeE, PaymentMethodUrls} from '../../types'
import {NoPaymentMethodRootRoot, StyledAddOtherMethod, StyledCCBillSection} from './style'

type NoPreferredPaymentMethodVariantProps = {
    paymentMethodUrls?: PaymentMethodUrls | null
    apiCallback: ((onlyLinks: boolean, withOnlyLink: boolean) => void) | undefined
    isUpdateCreditCard?: boolean
    isMonthlySupport?: boolean
}

const NoPreferredPaymentMethodVariant: FC<NoPreferredPaymentMethodVariantProps> = ({
    paymentMethodUrls,
    apiCallback,
    isUpdateCreditCard,
    isMonthlySupport
}) => {
    const {t} = useTranslation()
    const {height: vh} = useWindowDimensions()
    const iFrameRef = useRef<HTMLIFrameElement>(null)
    const praxisDataForIframe = paymentMethodUrls?.[CreditCardsNameE.PRAXIS_CREDIT_CARD]
    const SESSION_DURATION = 5 // minutes

    const onTimeExpired = useCallback(() => {
        if (praxisDataForIframe && iFrameRef.current) {
            iFrameRef.current.src = ''
            apiCallback?.(true, true)
        }
    }, [apiCallback])

    const onChooseCCBillPaymentMethod = ({type, url}: {type: CreditCardTypeE; url?: string}) => {
        if (type === CreditCardTypeE.CCBILL && url) {
            window.location.replace(url)
        }
    }

    return (
        <NoPaymentMethodRootRoot>
            {/* Session time countdown */}
            {!!praxisDataForIframe && (
                <div>
                    <SessionExpireTimer minutes={SESSION_DURATION} onComplete={onTimeExpired} isReInitTimer />
                </div>
            )}

            {paymentMethodUrls && !!paymentMethodUrls[CreditCardsNameE.CCBIL_CREDIT_CARD] && (
                <StyledCCBillSection>
                    {t('choose_payment_method:processed_by_ccbill')}
                    <span
                        onClick={() =>
                            onChooseCCBillPaymentMethod({
                                type: CreditCardTypeE.CCBILL,
                                url: paymentMethodUrls
                                    ? paymentMethodUrls[CreditCardsNameE.CCBIL_CREDIT_CARD]
                                    : undefined
                            })
                        }
                    >
                        {t('choose_payment_method:change_to_ccbill')}
                        <RedirectIcon />
                    </span>
                </StyledCCBillSection>
            )}

            {!!praxisDataForIframe && (
                <Fragment>
                    {/* <StyledOtherMethodDescription>{praxisDataForIframe.title}</StyledOtherMethodDescription> */}
                    <StyledAddOtherMethod 
                        height={vh} 
                        isUpdateCreditCard={isUpdateCreditCard}
                        isMonthlySupport={isMonthlySupport}
                        >
                        <iframe ref={iFrameRef} src={praxisDataForIframe} height={'100%'} width={'100%'} />
                    </StyledAddOtherMethod>
                </Fragment>
            )}
        </NoPaymentMethodRootRoot>
    )
}

export default NoPreferredPaymentMethodVariant
