import styled, {css} from 'styled-components'

export const StyledCardWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: ${spacing}px;
        background: ${palette.primary[900]};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
        padding: ${spacing * 0.75}px ${spacing}px;
        cursor: pointer;
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledCardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const StyledCardContentImages = styled.div`
    ${({theme: {palette, spacing}}) => css`
        font-weight: 500;
        color: ${palette.neutral.white};
        display: flex;
        align-items: center;
        gap: ${spacing}px;

        & svg {
            margin-top: 4px;
        }
    `}
`

export const StyledSubTitle = styled.p`
    ${({theme: {palette, typography}}) => css`
        ${typography.textXxs}
        font-weight: 400;
        color: ${palette.neutral.white};
        text-align: start;
    `}
`
