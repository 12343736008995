import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from './keys'
import {BILLING_API} from '../services/billing.http'
import {SendBillingInfoPayloadType} from '../types'

type useEditBillingInfoProps = {
    onSuccess: () => void
}

export const useEditBillingInfo = ({onSuccess}: useEditBillingInfoProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.edit_billing_info],
        mutationFn: ({body, guestId}: {body: SendBillingInfoPayloadType; guestId: number}) =>
            BILLING_API.editBillingInfo({body, guestId}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.billing_info]})
            onSuccess()
        }
    })
}
