import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {PROFILE_API} from '../services/profile.http'

export const useGetGuest = (id: number | string, enabled?: boolean) => {
    return useQuery({
        queryKey: [QUERY_KEYS.guest_profile, id],
        queryFn: () => PROFILE_API.getGuestProfile(id),
        enabled: !!id && enabled
    })
}
