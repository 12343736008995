import React from 'react'
import {useTranslation} from 'react-i18next'
import {HasPaymentMethodRoot, StyledButtonContent, StyledUpdateActiveCardButton} from './style'
import ActivePaymentMethod from '../active-payment-method/ActivePaymentMethod'
import {useMe} from '@/features/authentication/queries/useMe'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {GuestType} from '@/features/authentication/types'
import {CreditCardsNameE, PaymentMethodUrls} from '../../types'
import {Button} from '@/components/ui/button/Button'
import {useNavigate} from 'react-router-dom'
import {useRootStore} from '@/store'
import {selectBuyMorgisModal, selectChoosePaymentsMethodModal} from '@/store/selectors'
import {UpdatePaymentIcon} from '@/assets/icons/icons'
import {ROUTE_UPDATE_CREDIT_CARD} from '@/utilities/constants/routeNames'
import { LocalStorageManager } from '@/utilities/localStorage'

type HasPreferredPaymentMethodVariantProps = {
    paymentMethodUrls?: PaymentMethodUrls | null
    onActiveCardClick?: () => void
}

const HasPreferredPaymentMethodVariant: React.FC<HasPreferredPaymentMethodVariantProps> = ({
    paymentMethodUrls,
    onActiveCardClick
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {closeModal: closeBuyMorgisModal} = useRootStore(selectBuyMorgisModal)
    const {closeModal} = useRootStore(selectChoosePaymentsMethodModal)
    const {data} = useMe()
    const user = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null
    const platform = user?.type_attributes?.preferred_payment_method?.platform
    const activeCreditCard = user?.type_attributes?.preferred_payment_method
        ? user.type_attributes.preferred_payment_method.last4
        : null

    const onUpdateActiveCardClick = () => {
        navigate(ROUTE_UPDATE_CREDIT_CARD)
        closeModal()
        closeBuyMorgisModal()
    }

    return (
        <HasPaymentMethodRoot>
            <ActivePaymentMethod
                activeCreditCard={activeCreditCard}
                onActiveCardClick={() => {
                    onActiveCardClick?.()
                    LocalStorageManager.openBuyMorgisModal.remove()
                }}
                url={paymentMethodUrls ? paymentMethodUrls[CreditCardsNameE.PRAXIS_CREDIT_CARD] : undefined}
                type={platform}
            />

            <StyledUpdateActiveCardButton>
                <Button variant="outlined" onClick={onUpdateActiveCardClick}>
                    <StyledButtonContent>
                        <UpdatePaymentIcon />
                        {t('choose_payment_method:update_active_card')}
                    </StyledButtonContent>
                </Button>
            </StyledUpdateActiveCardButton>
        </HasPaymentMethodRoot>
    )
}

export default HasPreferredPaymentMethodVariant
