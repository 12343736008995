import {
    ROUTE_GOAL,
    ROUTE_HOST_PAYMENT_METHOD,
    ROUTE_HOST_PROFILE,
    ROUTE_MESSAGE_CENTER_CHAT,
    ROUTE_MY_MICROMORGI_BALANCE
} from '@/utilities/constants/routeNames'
import {NotificationsTypesE} from '../services/notifications.schema'
import {USER_ROLE_GUEST, USER_ROLE_HOST} from '@/utilities/constants/user'
import {generatePath} from 'react-router-dom'
import {LocalStorageManager} from '@/utilities/localStorage'
import {GoalType} from '@/features/goal/types'
import {NotificationsType} from '../types'
import {User} from '@/features/authentication/types'
import {DefaultTheme} from 'styled-components'

export const remapNotificationType = (notification: string) => {
    switch (notification) {
        case NotificationsTypesE.GUEST_CHANGE_GIFT_AMOUNT:
        case NotificationsTypesE.ID_CARD_REJECTED:
        case NotificationsTypesE.DESCRIPTION_DECLINED:
        case NotificationsTypesE.PHOTO_DECLINED:
        case NotificationsTypesE.HOST_VIDEO_DECLINED:
        case NotificationsTypesE.HOST_MERCH_CANCELED:
        case NotificationsTypesE.HOST_REJECTED_PAYMENT_ID_CARD:
        case NotificationsTypesE.HOST_REJECTED_PAYMENT_NO_METHOD:
        case NotificationsTypesE.HOST_REJECTED_PAYMENT_MIN_50_USD:
        case NotificationsTypesE.HOST_REJECTED_PAYMENT_GENERAL:
        case NotificationsTypesE.BLOCKED_GUEST:
        case NotificationsTypesE.HOST_BLOCKED_GUEST:
        case NotificationsTypesE.GUEST_BLOCKED_HOST:
        case NotificationsTypesE.INVALID_CARD_SUBSCRIPTION_CANCELED:
        case NotificationsTypesE.GUEST_CANCELED_SUBSCRIPTION:
        case NotificationsTypesE.GIFT_INACTIVITY_REMINDER:
        case NotificationsTypesE.BACK_ID_CARD_REJECTED:
        case NotificationsTypesE.FRONT_ID_CARD_REJECTED:
        case NotificationsTypesE.SELFIE_ID_CARD_REJECTED:
            return NotificationsTypesE.ALERT
        case NotificationsTypesE.GUEST_BUY_MICROMORGI_PACKAGE:
        case NotificationsTypesE.VERIFIED_ID_CARD:
        case NotificationsTypesE.DESCRIPTION_APPROVED:
        case NotificationsTypesE.PHOTO_APPROVED:
        case NotificationsTypesE.HOST_VIDEO_APPROVED:
        case NotificationsTypesE.GUEST_NEW_GIFT:
        case NotificationsTypesE.GUEST_CASHBACK:
            return NotificationsTypesE.CONGRATULATIONS
        case NotificationsTypesE.GUEST_FIRST_GIFT_TO_HOST:
        case NotificationsTypesE.HOST_FIRST_GIFT_FROM_GUEST:
        case NotificationsTypesE.HOST_RECEIVE_MICROMORGI:
        case NotificationsTypesE.GUEST_GOT_BONUS:
        case NotificationsTypesE.HOST_GOT_BONUS:
        case NotificationsTypesE.HOST_NEW_GIFT:
            return NotificationsTypesE.GIFT
        case NotificationsTypesE.MORGI_APPRECIATE:
            return NotificationsTypesE.APPRECIATE
        case NotificationsTypesE.GUEST_LOGIN:
        case NotificationsTypesE.HOST_LOGIN:
        case NotificationsTypesE.HOST_MERCH_IN_ELABORATION:
        case NotificationsTypesE.HOST_MERCH_SENT:
            return NotificationsTypesE.INFO
        case NotificationsTypesE.GUEST_REVERRED_HOST:
        case NotificationsTypesE.GUEST_WELCOME_FROM_HOST:
            return NotificationsTypesE.REFER
        case NotificationsTypesE.GUEST_RENEWED_GIFT:
        case NotificationsTypesE.HOST_RENEWED_GIFT:
            return NotificationsTypesE.RENEW
        case NotificationsTypesE.TELEGRAM_BOT:
            return NotificationsTypesE.TELEGRAM
        case NotificationsTypesE.GOT_BONUS_COUPON:
        case NotificationsTypesE.GOT_REFUNDED_GIFT_COUPON:
            return NotificationsTypesE.COUPON
        case NotificationsTypesE.GUEST_PAUSE_CONNECTION:
            return NotificationsTypesE.PAUSE
        case NotificationsTypesE.GUEST_FREE_SUBSCRIPTION:
        case NotificationsTypesE.HOST_FREE_SUBSCRIPTION:
            return NotificationsTypesE.CONNECTION
        case NotificationsTypesE.CONVERTER_FIRST_MESSAGE:
            return NotificationsTypesE.FIRST_MESSAGE
        case NotificationsTypesE.TRANSACTION_GOAL:
        case NotificationsTypesE.HOST_GOAL_APPROVED:
        case NotificationsTypesE.HOST_GOAL_ACTIVATED:
        case NotificationsTypesE.HOST_GOAL_AMOUNT_REACHED:
        case NotificationsTypesE.HOST_GOAL_SUSPENDED:
        case NotificationsTypesE.HOST_GOAL_PROOF_APPROVED:
        case NotificationsTypesE.GUEST_GOAL_COMPLETED:
        case NotificationsTypesE.GUEST_WELCOME_FROM_GOAL:
        case NotificationsTypesE.HOST_GOAL_PROOF_DECLINED:
            return NotificationsTypesE.GOAL
        case NotificationsTypesE.TOP_FANS_COMPLETED:
            return NotificationsTypesE.TOP_FAN_CONGRATULATIONS
        default:
            return NotificationsTypesE.INFO
    }
}

export const getColor = (typeRemapped: string, theme: DefaultTheme) => {
    switch (typeRemapped) {
        case NotificationsTypesE.INFO:
            return theme.palette.primary['200']
        case NotificationsTypesE.ALERT:
            return theme.palette.danger['25']
        case NotificationsTypesE.TOP_FAN_CONGRATULATIONS:
        case NotificationsTypesE.CONGRATULATIONS:
            return theme.palette.success['500']
        case NotificationsTypesE.APPRECIATE:
        case NotificationsTypesE.GIFT:
        case NotificationsTypesE.COUPON:
        case NotificationsTypesE.PAUSE:
            return theme.palette.tertiary['25']
        case NotificationsTypesE.RENEW:
        case NotificationsTypesE.CONNECTION:
            return theme.palette.primary['200']
        default:
            return theme.palette.primary['200']
    }
}

type shouldGoToProps = {
    type?: string
    typeRemapped?: string
    notification: NotificationsType
    user?: User
    goalsList?: GoalType[]
    goal?: GoalType
    invalidateMoengage: () => void
    channelId?: string | null
}

export const shouldGoTo = ({
    type,
    typeRemapped,
    notification,
    user,
    goalsList,
    goal,
    invalidateMoengage,
    channelId
}: shouldGoToProps) => {
    if (type === NotificationsTypesE.MOENGAGE_NOTIFICATION) {
        invalidateMoengage()
    }

    if (typeRemapped === NotificationsTypesE.COUPON || type === NotificationsTypesE.GUEST_CASHBACK) {
        return ROUTE_MY_MICROMORGI_BALANCE
    }

    if (typeRemapped === NotificationsTypesE.REFER && notification.ref_username) {
        return generatePath(ROUTE_HOST_PROFILE, {username: notification.ref_username})
    }

    if (type === NotificationsTypesE.VERIFIED_ID_CARD) {
        return ROUTE_HOST_PAYMENT_METHOD
    }

    if (type === NotificationsTypesE.GUEST_WELCOME_FROM_HOST && notification.ref_username) {
        return generatePath(ROUTE_HOST_PROFILE, {username: notification.ref_username})
    }

    if (
        !notification.is_ref_user_blocked &&
        typeRemapped !== NotificationsTypesE.ALERT &&
        typeRemapped !== NotificationsTypesE.GOAL &&
        channelId
    ) {
        return ROUTE_MESSAGE_CENTER_CHAT.replace(':channel', channelId)
    }

    if (user?.type === USER_ROLE_HOST && typeRemapped === NotificationsTypesE.GOAL && notification.goal_id) {
        const currentGoal = goalsList?.find(({id}) => id === notification?.goal_id)
        if (currentGoal) {
            LocalStorageManager.rookieIdForGoal.set(user.id)
            return generatePath(ROUTE_GOAL, {slug: `${currentGoal?.slug}-${currentGoal?.id}`})
        }
    }

    if (user?.type === USER_ROLE_GUEST && typeRemapped === NotificationsTypesE.GOAL && notification?.goal_id) {
        return generatePath(ROUTE_GOAL, {slug: `${goal?.slug}-${goal?.id}`})
    }
}
