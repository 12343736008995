import React from 'react'
import {StyledCardContent, StyledCardContentImages, StyledCardWrapper, StyledSubTitle} from './style'
import {CreditCardIcon, SquareWithArrowIcon} from '@/assets/icons/icons'
import {useTranslation} from 'react-i18next'

type PaymentMethodCardProps = {
    onActiveCardClick?: () => void
    icon?: string
    subTitle?: string
    external?: boolean
}

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({onActiveCardClick, icon, subTitle, external = false}) => {
    const {t} = useTranslation()

    return (
        <StyledCardWrapper onClick={onActiveCardClick}>
            <StyledCardContent>
                {icon && (
                    <StyledCardContentImages>
                        <CreditCardIcon />
                        {t('buy_micromorgies:pay_with_active_card')}
                    </StyledCardContentImages>
                )}

                <StyledSubTitle>{subTitle}</StyledSubTitle>
            </StyledCardContent>
            {external && <SquareWithArrowIcon />}
        </StyledCardWrapper>
    )
}

export default PaymentMethodCard
