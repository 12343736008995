// Mega gift
export const MEGA_GIFT = {
    cost: 5000,
    dollarAmount: 500
}

// Points for action
export const ACTION_POINTS = {
    connectedBotPoints: 40,
    addCaptionPoints: 10,
    editRookieMainPoints: 20,
    referGuestPoints: 30,
    messageCenterAnswer: 40
}
export const ACTION_MORGIS = {
    referGuestMorgis: 10
}

export const GIFTS_AMOUNTS_LIST = [5, 10, 25, 50, 100]

export const MORGI_LIST = [3, 10, 25, 50, 100]

export const PAID_MEDIA_AMOUNTS = [
    {id: 0, amount: 3},
    {id: 1, amount: 10},
    {id: 2, amount: 25},
    {id: 3, amount: 50},
    {id: 4, amount: 100},
    {id: 5, amount: 200}
]

export const FIRST_PAID_MESSAGE_AMOUNTS = [
    {id: 0, micromorgi_count: 3, price: 3},
    {id: 1, micromorgi_count: 10, price: 10},
    {id: 2, micromorgi_count: 25, price: 25},
    {id: 3, micromorgi_count: 50, price: 50},
    {id: 4, micromorgi_count: 100, price: 100},
    {id: 5, micromorgi_count: 200, price: 200}
]

export const AMOUNTS_TO_OPEN_CONNECTION = [
    {id: 0, amount: 5},
    {id: 1, amount: 50},
    {id: 2, amount: 100},
    {id: 3, amount: 200},
    {id: 5, amount: 1000}
]
