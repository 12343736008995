import {CreditCardsNameE, PaymentMethodUrls, SocialPlatformTypeE} from '../types'
import googlePayLogo from '@assets/images/google_pay_logo.png'
import pixPayLogo from '@assets/images/pix-pay-logo.png'
import applePayIcon from '@assets/images/applePay.png'
import billingsMethodsIcon from '@assets/images/billingsMethods.png'

const isAPMLink = ([key, value]: [string, string]) => {
    if (key !== CreditCardsNameE.CCBIL_CREDIT_CARD && key !== CreditCardsNameE.PRAXIS_CREDIT_CARD)
        return {key: key, value: value}
}

const checkIfIncluded = (key: string, condition: SocialPlatformTypeE) => key.toLowerCase().includes(condition)

//filter an Object, return links without 'praxis_credit_card', 'ccbill_credit_card';
// remap and renamed keys according constants SocialPlatformTypeE
export const remapAndFilterAPMLinks = (
    paymentMethodUrls: PaymentMethodUrls | null
): {name: SocialPlatformTypeE; url: string; icon: string}[] => {
    const isIOS = /iPad|iPhone|iPod|Mac/.test(navigator.userAgent)

    if (!paymentMethodUrls) {
        return []
    }

    const filteredAPMLinks = Object.entries(paymentMethodUrls)
        .filter(isAPMLink)
        .map(([key, value]) => {
            switch (true) {
                case checkIfIncluded(key, SocialPlatformTypeE.GOOGLE):
                    return !isIOS ? {name: SocialPlatformTypeE.GOOGLE, url: value, icon: googlePayLogo} : null
                case checkIfIncluded(key, SocialPlatformTypeE.PIX):
                case checkIfIncluded(key, SocialPlatformTypeE.DIRECT_24_PAY_WALL):
                    return {name: SocialPlatformTypeE.PIX, url: value, icon: pixPayLogo}
                case checkIfIncluded(key, SocialPlatformTypeE.APPLE) && isIOS:
                    return {name: SocialPlatformTypeE.APPLE, url: value, icon: applePayIcon}
                default:
                    return  {name: key.split(' - ')[2], url: value, icon: billingsMethodsIcon}
            }
        })
        .filter((link): link is {name: SocialPlatformTypeE; url: string; icon: string} => link !== null) // Type guard to filter out null values

    return filteredAPMLinks
}