import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {BILLING_API} from '../services/billing.http'

export const useGetBillingInfo = (guestId: number) => {
    return useQuery({
        queryKey: [QUERY_KEYS.billing_info],
        queryFn: () => BILLING_API.getBillingInfo(guestId)
    })
}
