import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from './keys'
import {CHAT_API} from '../services/chat.http'

export const useSendChatFile = (onSuccess?: () => void) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.send_file],
        mutationFn: ({channelID, type, formDataObject}: {channelID: string; type: string; formDataObject: FormData}) =>
            CHAT_API.sendChatFile(channelID, type, formDataObject),
        onSettled: () => {
            onSuccess?.()
        }
    })
}
