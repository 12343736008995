import {useTheme} from 'styled-components'
import {getVariantStyle} from './alertContent'
import {StyledCloseIcon, StyledContainer, StyledContent, StyledRoot} from './style'
import {ReactNode, useEffect} from 'react'
import {DefaultNamespace} from 'i18next'
import {AlertStatus} from '@/features/authentication/types'
import {useTranslation} from 'react-i18next'
import {AppStatusesE} from '@/utilities/constants/appStatus'

type AlertType = {
    variant: AlertStatus
    dismissTimeout?: number
    message?: string
    title?: DefaultNamespace | ReactNode | string
    onDismiss?: () => void
    onAlertClick?: () => void
}

export const Alert = ({variant, message, title, onDismiss, dismissTimeout, onAlertClick}: AlertType) => {
    const {t} = useTranslation()
    const theme = useTheme()

    useEffect(() => {
        if (dismissTimeout) {
            const timeoutId = setTimeout(() => {
                onDismiss?.()
            }, +dismissTimeout)
            return () => {
                clearTimeout(timeoutId)
            }
        }
    }, [dismissTimeout, onDismiss])
    const correctVariant = !!title || !!message ? variant : AppStatusesE.FAILURE
    const variantStyle = getVariantStyle(correctVariant, theme)

    return (
        <StyledRoot color={variantStyle.color} backgroundColor={variantStyle.backgroundColor} onClick={onAlertClick}>
            <StyledContainer>
                {!!variantStyle.icon && variantStyle.icon}
                <StyledContent>
                    {!!title && <h3>{title}</h3>}
                    {!!message && <div>{message}</div>}
                    {!title && !message && <div>{t('attentionModal:somethingWrong:title')}</div>}
                </StyledContent>
                {onDismiss && (
                    <StyledCloseIcon
                        fill={variantStyle.closeIconColor}
                        onClick={(e: MouseEvent) => {
                            e.stopPropagation()
                            onDismiss()
                        }}
                    />
                )}
            </StyledContainer>
        </StyledRoot>
    )
}
