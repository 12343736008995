import {useMe} from '@/features/authentication/queries/useMe.ts'

import {AxiosError} from 'axios'
import {Navigate, Outlet, useSearchParams} from 'react-router-dom'
import * as routes from '@/utilities/constants/routeNames'
import {SessionStorageManager} from '@/utilities/sessionStorage'
import {USER_ROLE_GUEST, USER_ROLE_HOST, USER_ROLE_REPRESENTATIVE} from '@/utilities/constants/user'

export const PublicRoute = () => {
    const {loggedIn, isError, data: user, error} = useMe(false)
    const [searchParams] = useSearchParams()
    const goalReferral = searchParams.get('goal')

    const getCorrectRedirectPath = () => {
        const isGuest = user?.type === USER_ROLE_GUEST
        const isHost = user?.type === USER_ROLE_HOST
        const isRepresentative = user?.type === USER_ROLE_REPRESENTATIVE
        const isFirstLogin = !!SessionStorageManager.checkIsFirstLogin.get()

        switch (true) {
            case isGuest && !!goalReferral:
                return routes.ROUTE_GOAL.replace(':slug', goalReferral ?? '')
            case isGuest && isFirstLogin:
                return routes.ROUTE_ON_BOARD_TOUR
            case isGuest:
                return routes.ROUTE_HOME
            case isHost && isFirstLogin:
                return routes.ROUTE_WELCOME_HOST
            case isHost:
                return routes.ROUTE_HOST_MY_PROFILE
            case isRepresentative:
                return routes.ROUTE_MESSAGE_CENTER
            default:
                return routes.ROUTE_WHAT_IS_MORGI
        }
    }

    if (isError) {
        const allowableStatusError = [401]
        const errorStatus = (error as AxiosError)?.response?.status as number
        if (!allowableStatusError.includes(errorStatus)) throw error
    }

    return loggedIn ? <Navigate to={getCorrectRedirectPath()} /> : <Outlet />
}
