import {useMutation} from '@tanstack/react-query'
import {CHAT_API} from '../services/chat.http'
import {ChannelMessage} from '../types'
import {MUTATION_KEYS} from './keys'
import {GeneralError} from '@/utilities/getAPIErrorMessage'

type MessageResponse = {
    can_send_messages?: boolean
    paid_message_id?: number
    message?: {language: string; text: string; type: string; user_id: number}
}

export const useSendMessageToChannel = () => {
    return useMutation<MessageResponse, GeneralError, ChannelMessage>({
        mutationKey: [MUTATION_KEYS.send_message],
        mutationFn: (payload: ChannelMessage) => CHAT_API.sendMessage(payload)
    })
}
