import {PageHeader} from '@/components/commons/page-header/PageHeader'
import {Divider} from '@/components/ui/divider/Divider'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType, HostType} from '@/features/authentication/types'
import {GenderKeyNameE} from '@/features/constants/constants'
import {useGoalsListOfUser} from '@/features/goal/queries/useGoalsListOfUser'
import {MegaGiftBanner} from '@/features/mega-gift/components/mega-gift-banner/MegaGiftBanner'
import {TopFanBanner} from '@/features/mega-gift/components/top-fan-banner/TopFanBanner'
import {ThanksYousHostSection} from '@/features/thank-yous/components/thank-yous-rookie-section/ThanksYousHostSection'
import {TranslateSwitch} from '@/features/translation/components/translate-switch/TranslateSwitch'
import {Translate} from '@/features/translation/components/translate/Translate'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {DirectionsE, MediaTypeE} from '@/types'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {computeAge} from '@/utilities/helpers'
import {FC, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {HostProfileCtaSection} from '../host-profile-cta-section/HostProfileCtaSection'
import {HostProfileGoalsSection} from '../host-profile-goals-section/HostProfileGoalsSection'
import {HostProfilePhotoSlider} from '../host-profile-photo-slider/HostProfilePhotoSlider'
import {HostTopics} from '../host-profile-topics/HostTopics'
import {HostProfileVideo} from '../host-profile-video/HostProfileVideo'
import {
    StyledHostDescription,
    StyledHostFullName,
    StyledHostTitle,
    StyledMainHostInfo,
    StyledOnlineCircle,
    StyledPagination,
    StyledPaginationItem,
    StyledSplide,
    StyledSplideSlide,
    StyledSplideTrack,
    StyledWrapper,
    carouselStyles,
    sectionStyles,
    topicsListStyles
} from './style'
import {commonTopicsNames} from '../../helpers/helpers'
import dayjs, {UnitsE} from '@/dayjs'
import {useHandleError} from '@/hooks/useHandleError'
import {useTheme} from 'styled-components'
import {HostProfileSurprisesSection} from '../host-profile-surprises-section/HostProfileSurprisesSection'
import {HostProfileSection} from '../host-profile-section/HostProfileSection'
import {useGetConnectedUser} from '@/hooks/useGetConnectedUser'
import {SubscriptionStatusesE} from '@/features/chat/helpers/helpers'
import {SplideType} from '@/components/ui/carousel/Carousel'
import {MediaLightBox} from '@/components/ui/media-light-box/MediaLightBox'
import {selectRecurringGiftModal} from '@/store/selectors'
import {useRootStore} from '@/store'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import {ProfileSurpriseSection} from '../host-dashboard/profile-surprise-section/ProfileSurpriseSection'

type HostProfileProps = {
    hostUserName?: string
    isModal?: boolean
    setIsWatchedVideo?: () => void
    setScrolledImages?: () => void
    isWatchedVideo?: boolean
    scrolledImages?: number
}

type OptionsType = {
    type?: string
    arrows?: boolean
    rewind?: boolean
    pagination?: boolean
    gap?: number
    start?: number
    perPage?: number
    padding?: {right?: string; top?: string; left?: string; bottom?: string} | string
    direction?: DirectionsE | 'ttb'
}

export const HostProfile: FC<HostProfileProps> = ({
    hostUserName,
    isModal = false,
    setIsWatchedVideo,
    setScrolledImages,
    isWatchedVideo,
    scrolledImages
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const theme = useTheme()
    const {width} = useWindowDimensions()
    const [isMobile, setIsMobile] = useState(width <= 768)
    const [languagesUsed, setLanguagesUsed] = useState<string[]>([])
    const [isClickShare, setIsClickShare] = useState(false)
    const [isShowThankYous, setIsShowThankYous] = useState(true)
    useEffect(() => {
        setIsMobile(width <= 768)
    }, [width])
    // user
    const {data: user, isFetching: isLoadingMe} = useMe()
    const guestProfile = user as GuestType
    const isGuest = user?.type === USER_ROLE_GUEST
    const {
        data: hostByUsername,
        isFetching: isLoadingHost,
        isError,
        error
    } = useGetHost({filter: hostUserName, isUsername: true})

    useHandleError({isError, error, isBlankPage: true})
    const hostData = isGuest ? (hostByUsername as HostType) : (user as HostType)
    const hostFirstName = hostData?.type_attributes?.first_name
    const age = computeAge(`${hostData?.type_attributes?.birth_date}`)
    const isLoading = isLoadingMe || isLoadingHost
    // topics
    const comparedChatTopics = hostData?.chat_topics
    const comparedActivities = hostData?.favorite_activities
    const hostsWish = hostData?.type_attributes?.path
    // goals
    const {data: goals} = useGoalsListOfUser(hostData?.id)
    const hasPastGoals = hostData?.type_attributes?.has_past_goals
    // media
    const photos = hostData?.type_attributes?.photos
    const videos = hostData?.type_attributes?.videos
    // badges
    const MIN_COUNT_TOP_FANS_TO_SHOW_CARD = 2
    const isShowTopFanSection =
        !!hostData?.type_attributes?.top_fans_infos?.completed_counter &&
        hostData?.type_attributes?.top_fans_infos.completed_counter >= MIN_COUNT_TOP_FANS_TO_SHOW_CARD
    const isShowMegaGiftSection = !!hostData?.type_attributes?.mega_gifts_count

    const isSpecial = !!hostData?.type_attributes?.special_type
    const expertiseCategory = hostData?.type_attributes?.expertise?.category
    const expertise = hostData?.type_attributes?.expertise?.key_name
    const expertiseName = hostData?.type_attributes?.expertise?.name

    // mixpanel
    const activeFilters = guestProfile.type_attributes.leaders_carousels_filters
    const countryFilters = activeFilters
        ? guestProfile.type_attributes.leaders_carousels_filters?.filters.countries.map(country => country.name)
        : []
    const guestChatTopics = guestProfile?.chat_topics
    const guestActivities = guestProfile?.favorite_activities
    const hostChatTopics = hostData?.chat_topics?.length ? hostData?.chat_topics : []
    const hostActivities = hostData?.favorite_activities?.length ? hostData?.favorite_activities : []
    const [endTime, setEndTime] = useState<dayjs.Dayjs>(dayjs())
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    const handleSetEndTime = () => {
        const endTime = dayjs()
        setEndTime(endTime)
    }

    const startTime = dayjs()

    const mixpanelData = {
        carousel_card_number: 0,
        click_share: isClickShare,
        common_activities: commonTopicsNames(guestChatTopics, hostChatTopics),
        common_chat_topics: commonTopicsNames(guestActivities, hostActivities),
        country_filter_applied: countryFilters?.length ? countryFilters : 'No',
        from: 'Profile',
        morgi_balance: guestProfile.type_attributes.micro_morgi_balance,
        scrolled_images: scrolledImages,
        time_on_profile: startTime.diff(endTime, UnitsE.MILLISECOND),
        watched_video: isWatchedVideo
    }

    const onGetLanguages = (lang: string) => {
        setLanguagesUsed(prevState => [...prevState, lang])
    }

    useEffect(() => {
        return () => setLanguagesUsed([])
    }, [])

    const hostGender = useMemo(() => {
        switch (hostData?.gender?.id) {
            case 1:
                return GenderKeyNameE.MALE
            case 2:
                return GenderKeyNameE.FEMALE
            default:
                return GenderKeyNameE.OTHER
        }
    }, [hostData])

    const correctOption: OptionsType = {
        rewind: true,
        arrows: false,
        gap: 10,
        direction: theme.direction
    }

    const {connectedUser: connectedHost} = useGetConnectedUser(hostData?.id)
    const BLOCKED_VIDEO_INDEX = 2
    const subscriptionStatusActive =
        !!connectedHost && connectedHost?.subscription_status === SubscriptionStatusesE.ACTIVE

    const displayedVideos = !subscriptionStatusActive && isGuest ? videos?.slice(0, BLOCKED_VIDEO_INDEX) : videos
    const [activeIndex, setActiveIndex] = useState(0)

    const onMoveVideoCarousel = ({newIndex}: {splide: SplideType; newIndex: number; prevIndex: number}) => {
        setActiveIndex(newIndex)
    }

    const {openModal: openGiftModal} = useRootStore(selectRecurringGiftModal)
    const onOpenVideoPreview = () => {
        setIsPreviewOpen(true)
        setIsWatchedVideo?.()
    }
    const handleClickVideo = () => {
        if (!subscriptionStatusActive && isGuest && activeIndex === 1) {
            openGiftModal({hostID: `${hostData?.id}`})
        } else {
            onOpenVideoPreview()
        }
    }
    const BLOCKED_LIGHTBOX_VIDEOS_COUNT = 1
    const correctVideosForLightBox =
        !subscriptionStatusActive && isGuest
            ? displayedVideos?.slice(0, BLOCKED_LIGHTBOX_VIDEOS_COUNT)
            : displayedVideos

    const remappedVideosForLightBox = correctVideosForLightBox?.length
        ? correctVideosForLightBox?.map(video => ({
              type: MediaTypeE.VIDEO,
              url: video.url || video.blurred_url
          }))
        : []

    return isLoading ? (
        <Spinner />
    ) : (
        <>
            {!isModal && (
                <PageHeader
                    title={
                        isMobile && (
                            <>
                                {hostData?.is_online && <StyledOnlineCircle />}
                                {hostData?.full_name}, {age}
                            </>
                        )
                    }
                    onBack={() => navigate(-1)}
                />
            )}
            {isMobile && isModal && (
                <StyledHostFullName isModal={isModal}>
                    {hostData?.is_online && <StyledOnlineCircle />}
                    {`${hostData?.full_name}, ${age} `}
                </StyledHostFullName>
            )}

            <StyledWrapper>
                <HostProfilePhotoSlider
                    photos={photos ?? []}
                    isGuest={isGuest}
                    isSpecial={isSpecial}
                    expertiseCategory={expertiseCategory}
                    expertise={expertise}
                    isVerifiedHost={!!hostData?.id_verified || !!hostData?.has_verified_id}
                    hostGenderId={hostData?.gender?.id}
                    hostName={hostData?.full_name}
                    setScrolledImages={setScrolledImages}
                    host={hostData}
                    mixpanelData={mixpanelData}
                    handleSetEndTime={handleSetEndTime}
                />
                <StyledMainHostInfo>
                    {!isMobile && (
                        <StyledHostTitle>
                            <StyledHostFullName resizeForModal={isModal}>
                                {hostData?.is_online && <StyledOnlineCircle />}
                                {`${hostData?.full_name} `}
                            </StyledHostFullName>
                            <span>{`, ${age}`}</span>
                        </StyledHostTitle>
                    )}
                    {!!videos?.length && (
                        <StyledSplide
                            options={correctOption}
                            hasTrack={false}
                            $carouselStyles={() => carouselStyles({theme})}
                            onMove={(splide: SplideType, newIndex: number, prevIndex: number) =>
                                onMoveVideoCarousel({splide, newIndex, prevIndex})
                            }
                        >
                            <StyledSplideTrack>
                                {displayedVideos?.map((video, idx) => (
                                    <StyledSplideSlide key={`${video?.id}-${idx}}`} onClick={handleClickVideo}>
                                        <HostProfileVideo
                                            setIsWatchedVideo={setIsWatchedVideo}
                                            video={video?.url || video?.blurred_url}
                                            hostName={`${hostFirstName}`}
                                            isBlockedVideos={!subscriptionStatusActive && isGuest && idx >= 1}
                                            currentBlockedVideo={
                                                !subscriptionStatusActive && isGuest && activeIndex === 1
                                            }
                                            host={hostData}
                                        />
                                    </StyledSplideSlide>
                                ))}
                            </StyledSplideTrack>
                            {videos?.length > 1 && (
                                <StyledPagination>
                                    {videos?.map((video, idx) => (
                                        <StyledPaginationItem
                                            key={`${idx}-${video?.id}}`}
                                            isActive={idx === activeIndex}
                                        />
                                    ))}
                                </StyledPagination>
                            )}
                        </StyledSplide>
                    )}
                    <HostProfileSection title={t('common:about')}>
                        {!!hostData?.description && (
                            <StyledHostDescription>
                                <Translate content={hostData?.description} onGetNativeLanguageOfText={onGetLanguages} />
                            </StyledHostDescription>
                        )}
                        <HostTopics
                            comparedChatTopics={comparedChatTopics}
                            comparedActivities={comparedActivities}
                            hostsWish={hostsWish || undefined}
                            withChatTopics
                            withActivities
                            withWishes
                            isSpecialHost={hostData?.type_attributes?.is_special}
                            hostExpertise={expertise}
                            hostExpertiseCategory={expertiseCategory}
                            expertiseName={expertiseName}
                            topicsListStyles={topicsListStyles}
                        />
                    </HostProfileSection>

                    <TranslateSwitch
                        languagesUsed={{
                            languages: languagesUsed,
                            count: 1
                        }}
                    />
                    {isGuest ? <HostProfileSurprisesSection hostData={hostData} /> : <ProfileSurpriseSection />}

                    <HostProfileSection title={t('common:goals')}>
                        {!!goals && (
                            <HostProfileGoalsSection
                                goals={goals}
                                hasPastGoals={hasPastGoals}
                                hostFirstName={`${hostFirstName}`}
                                userGuest={isGuest}
                                hostId={hostData?.id}
                            />
                        )}
                    </HostProfileSection>

                    {(isShowMegaGiftSection || isShowTopFanSection) && (
                        <HostProfileSection sectionStyles={sectionStyles} title={t('common:my_achievments')}>
                            {isShowMegaGiftSection && <MegaGiftBanner userName={hostUserName || hostData?.username} />}
                            {isShowTopFanSection && <TopFanBanner userName={hostUserName || hostData?.username} />}
                        </HostProfileSection>
                    )}

                    {isGuest && isShowThankYous && (
                        <HostProfileSection title={t('common:thank_yous')}>
                            <ThanksYousHostSection
                                hostData={hostData}
                                inHostProfile
                                setIsShowThankYous={setIsShowThankYous}
                            />
                        </HostProfileSection>
                    )}

                    {!isMobile && <Divider />}
                    <HostProfileCtaSection
                        userGuest={isGuest}
                        hostFirstName={`${hostFirstName}`}
                        hostId={hostData?.id}
                        hostUserName={hostData?.username}
                        hostGender={hostGender}
                        setIsClickShare={setIsClickShare}
                    />
                </StyledMainHostInfo>
            </StyledWrapper>
            {isPreviewOpen && !!remappedVideosForLightBox.length && (
                <MediaLightBox
                    currentIndex={activeIndex}
                    media={remappedVideosForLightBox}
                    onClose={() => setIsPreviewOpen(false)}
                />
            )}
        </>
    )
}

HostProfile.displayName = 'HostProfile'
