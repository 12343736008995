import {LocalStorageManager} from '@utilities/localStorage.ts'
import dayjs from '@/dayjs.ts'
import {GuestType, HostPartialType} from '@/features/authentication/types.ts'
import {feedCardVariants} from '@/features/feed/components/feed-card/FeedCard.tsx'
import {DeckRules, FeedCard, FeedCardVariant} from '@/features/feed/types.ts'
import {
    computeValuesDifferenceBetweenObj,
    extractRandomElement,
    fillArrayFromObject,
    getDisjointPartitions,
    getRandInt,
    getRandomIndexesInRange,
    insertBeforeIndex
} from '@utilities/helpers'

export const convertTimeToLocalFromUTC = ({
    date,
    targetFormat = 'YYYY-MM-DD HH:mm:ss',
    newFormat = 'YYYY-MM-DD HH:mm:ss'
}: {
    date: string | Date
    targetFormat: string
    newFormat: string
}) => {
    const utcDate = dayjs.utc(date, targetFormat)
    return utcDate.local().format(newFormat)
}

/*
	The following function check the deck rules to respect on every request, because
	the deck can have special cards alongside the normal HostCard.

	Special cards available:
	- Marketing Card - shown 3 times per card session, every card must be shown with at least 3 normal cards of gap in the middle
	- Bot Teaser     - shown every one time per card session, up to 15 times, only if user is not connected to bot
	- Goal teaser    - shown one time per card session, up to 25 times, only if user has never donated to a goal
	- Filter card    - shown once every 2 card sessions, on a chance of 1:2 to show activities / chat topics
	- Cashback card  - shown 1 times every session until got cashback / offer is dismissed
	- MetaCombiCard  - shown every time is sent from backend
	- TopHostCard  - shown every odd page reload (1, 3, 5...) on 2nd position in the deck
*/
export const deckRules = {
    get: (
        guestProfile: GuestType,
        isFiltersExist: boolean,
        isTopHostExist: boolean,
        metaCombiCard: boolean,
        refreshCount?: number
    ) => {
        const hasGuestConnectedToTelegram = guestProfile.joined_telegram_bot_at
        const hasLeaderSupportedGoal = guestProfile.type_attributes.has_supported_goal

        const isAvailableCashbackOffer =
            guestProfile.type_attributes.cashback_offer &&
            guestProfile.type_attributes.cashback_offer?.is_available_cashback_offer

        // TRUE - the cashback offer time is ended
        // FALSE - the cashback offer time is not ended
        const isOfferDateExist =
            !!guestProfile?.type_attributes?.cashback_offer && !!guestProfile?.type_attributes?.cashback_offer?.end_date

        const offerEndDateLocal =
            guestProfile?.type_attributes?.cashback_offer?.end_date &&
            convertTimeToLocalFromUTC({
                date: guestProfile.type_attributes.cashback_offer.end_date,
                targetFormat: 'YYYY-MM-DD HH:mm:ss',
                newFormat: 'YYYY-MM-DD HH:mm:ss'
            })
        const isCashbackOfferTimeEnded =
            offerEndDateLocal &&
            guestProfile.type_attributes.cashback_offer &&
            guestProfile.type_attributes.cashback_offer.end_date &&
            Date.now() - new Date(offerEndDateLocal).getTime() > 0

        const {get} = LocalStorageManager.interactiveFeedSpecialCardsTracker
        const maxOverallQuantityForBotCard = 15
        const maxOverallQuantityForGoalGard = 25

        const canSeeBotCard =
            !hasGuestConnectedToTelegram && get(feedCardVariants.telegramBot) < maxOverallQuantityForBotCard

        const canSeeGoalCard =
            !hasLeaderSupportedGoal && get(feedCardVariants.goalTeaser) < maxOverallQuantityForGoalGard

        // if not in previous session, then show it now
        const canSeeFilterCard = isFiltersExist && !get(feedCardVariants.filter)

        const canSeeCashbackCard = !!isAvailableCashbackOffer && !isCashbackOfferTimeEnded && isOfferDateExist

        const canSeeTopHostCard =
            isTopHostExist && !!refreshCount && refreshCount % 2 === 1 && !get(feedCardVariants.topHost)

        // const canSeeMetaCombiCard = metaCombiCard && metaCombiCard?.name?.includes('card') // this must match the name that have to be shown in carousel
        return {
            [feedCardVariants.marketing]: 3,
            ...(canSeeBotCard && {[feedCardVariants.telegramBot]: 1}),
            ...(canSeeGoalCard && {[feedCardVariants.goalTeaser]: 1}),
            ...(canSeeFilterCard && {[feedCardVariants.filter]: 1}),
            ...(canSeeCashbackCard && {[feedCardVariants.cashback]: 1}),
            ...(metaCombiCard && {[feedCardVariants.moengage]: 1}),
            ...(canSeeTopHostCard && {[feedCardVariants.topHost]: 1})
        }
    },
    track: (cardType: FeedCardVariant) => {
        const {get, set} = LocalStorageManager.interactiveFeedSpecialCardsTracker
        const [curr, next] = LocalStorageManager.checkFeedFilterCardType.get() || [null, feedCardVariants.chatTopics]
        let nextHand = []
        switch (cardType) {
            case feedCardVariants.goalTeaser:
                set({[feedCardVariants.goalTeaser]: get(feedCardVariants.goalTeaser) + 1})
                break
            case feedCardVariants.telegramBot:
                set({
                    [feedCardVariants.telegramBot]: get(feedCardVariants.telegramBot) + 1
                })
                break
            case feedCardVariants.filter:
                if (curr === feedCardVariants.chatTopics && next === curr) {
                    nextHand = [feedCardVariants.chatTopics, feedCardVariants.favActivities]
                } else if (curr === feedCardVariants.chatTopics) {
                    nextHand = [feedCardVariants.favActivities, feedCardVariants.chatTopics]
                } else {
                    nextHand = [feedCardVariants.chatTopics, feedCardVariants.chatTopics]
                }
                LocalStorageManager.checkFeedFilterCardType.set(nextHand)
                set({[feedCardVariants.filter]: !get(feedCardVariants.filter)})
                break
            case feedCardVariants.topHost:
                set({
                    [feedCardVariants.topHost]: !get(feedCardVariants.topHost)
                })
                break
            default:
                return
        }
    }
}

/*
	The following function computes where to put the special cards inside the deck,
	Cards must follow some rules like:
	- there can't be 2 special cards in a row
*/
// TODO: the deck has this type: FeedCard[]
export const getSpecialCardsIndexes = (initialDeck: FeedCard[] | HostPartialType[], deckRules: DeckRules) => {
    if (!initialDeck.length) return []

    const deck = initialDeck
    // console.log('initial deck', deck)

    // Step 0: check how much are the special cards to place in the deck
    let totalSpecialCardsToPlace = 0
    if (deckRules) {
        Object.values(deckRules).forEach((v: number) => {
            totalSpecialCardsToPlace += v
        })
    }
    // console.log('totalSpecialCardsToPlace', totalSpecialCardsToPlace)
    if (totalSpecialCardsToPlace > 0) {
        // Step 1: partition the deck, one partition for each special card
        const partitions = getDisjointPartitions(deck, totalSpecialCardsToPlace)
        // console.log('partitions', partitions)
        // Step 2: search where to place special cards in each subdeck.
        // Take care to not put 2 special cards in a row when subdecks will be merged again
        const partitionsTargetIndexes: number[] = []

        partitions.forEach((p, idx) => {
            const startFrom = 1 //[ <blocked> , card2, card3, card4, card5]
            const endTo = p.length - 1 //[ <blocked> , card2, card3, card4, <blocked>]
            const targetIndex =
                idx === 0 && !!deckRules[feedCardVariants.topHost]
                    ? 1
                    : getRandomIndexesInRange(p, startFrom, endTo, 1)[0]
            partitionsTargetIndexes.push(targetIndex)
        })

        // Step 3: for each partition, place one special card on the target index for that partition
        const filteredDeckRules = Object.fromEntries(
            Object.entries(deckRules).filter(([key]) => key !== feedCardVariants.topHost)
        )

        const specialCardInstances = fillArrayFromObject(filteredDeckRules)
        partitionsTargetIndexes.forEach((targetIndex, partitionIndex) => {
            const cardType =
                partitionIndex === 0 && !!deckRules[feedCardVariants.topHost]
                    ? feedCardVariants.topHost
                    : extractRandomElement(specialCardInstances)
            const randIndex = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))

            // this is needed internally to choose the correct card data (e.g. random persona images)
            const randomIndexForCardData = cardType === feedCardVariants.marketing ? getRandInt(0, 7) : getRandInt(0, 3)

            partitions[partitionIndex] = insertBeforeIndex(partitions[partitionIndex], targetIndex, {
                id: `${cardType}${randIndex}`,
                cardType,
                index: randomIndexForCardData
            })
        })
        // console.log('final partitions', partitions)

        // Step 4: merge again partitions and return the new full deck
        // console.log('partitions flat', partitions.flat())
        return partitions.flat()
    }
    return deck
}

export const computeIndexes = ({
    initialDeck,
    continuePreviousSession = false,
    prevSession,
    guestProfile,
    isFiltersExist,
    isTopHostExist,
    metaCombiCard,
    refreshCount
}: {
    initialDeck: FeedCard[] | HostPartialType[]
    continuePreviousSession: boolean
    prevSession: DeckRules
    guestProfile: GuestType
    isFiltersExist: boolean
    isTopHostExist: boolean
    metaCombiCard: boolean
    refreshCount: number
}) => {
    const activeDeckRules: DeckRules = deckRules.get(
        guestProfile,
        isFiltersExist,
        isTopHostExist,
        metaCombiCard,
        refreshCount
    )
    // Prepare from previous not seen cards from last session
    const oldCardsCounter = Object.values(prevSession).filter(v => !v).length
    // console.log('oldCardsCounter', oldCardsCounter)

    if (!continuePreviousSession || !oldCardsCounter) {
        return getSpecialCardsIndexes(initialDeck, activeDeckRules)
    }
    // console.log('keep prev session')

    const remainingCardsFromPrevSession = oldCardsCounter //sessionDeckSize - (+LocalStorageManager.interactiveFeedSeenCardsCounter.get())
    // console.log('remainingCardsFromPrevSession', remainingCardsFromPrevSession)
    //const prevSessionState = LocalStorageManager.interactiveFeedSpecialCardsTracker.get() ?? {} /// TODO: must be decreased as soon as we show the card

    //console.log('prev session state', prevSessionState)
    // console.log('prev session state REDUX', prevSession)

    // give me only seen special card ids
    const prevSessionRules: DeckRules = {}
    const prevSpecialSeen = Object.entries(prevSession)
        .filter(([k, v]) => Number.isNaN(+k) && v)
        .map(([k]) => k)
    // console.log('prevSpecialSeen', prevSpecialSeen)

    // rebuild prev rules
    prevSpecialSeen.forEach(cID => {
        // TODO: next line cannot be quaranteed at this time, find a different solution
        // arrives like "filter124324344", and we use just "filter"
        const prefix = cID.replace(/[^a-zA-Z]/g, '') as FeedCardVariant // this is the name of the card
        deckRules.track(prefix)
        prevSessionRules[prefix] = (prevSessionRules[prefix] ?? 0) + 1
    })
    // console.log('prevSessionRules', prevSessionRules)
    const residualPrevRules = computeValuesDifferenceBetweenObj(activeDeckRules, prevSessionRules)

    const prevDeckPortion = initialDeck.slice(0, remainingCardsFromPrevSession)
    // console.log('prevDeckPortion', prevDeckPortion)
    const prevDeckIndexes = getSpecialCardsIndexes(prevDeckPortion, residualPrevRules)
    // console.log('prevDeckIndexes', prevDeckIndexes)
    const nextDeckPortion = initialDeck.slice(remainingCardsFromPrevSession)
    // console.log('nextDeckPortion', nextDeckPortion)

    // take the current available rules for the new deck
    // remove the one to show from the previous residual deck
    const nextDeckRules = computeValuesDifferenceBetweenObj(activeDeckRules, residualPrevRules)
    // console.log('nextDeckRules', nextDeckRules)
    const nextDeckIndexes = getSpecialCardsIndexes(nextDeckPortion, nextDeckRules)
    // console.log('nextDeckIndexes', nextDeckIndexes)
    return [...prevDeckIndexes, ...nextDeckIndexes]
}
