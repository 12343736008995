import {envVars} from '@/envVars'
import {PlaceAutocomplete} from '@/features/billing/components/place-autocomplete/PlaceAutocomplete'
import {APIProvider} from '@vis.gl/react-google-maps'
import {FC, FocusEvent, useEffect, useState} from 'react'

export type Address = {
    city: string
    zip_code: string
    address?: string
    raw_address?: string
    country_name: string
    country_code: string
    state_code: string
}

type AutoCompleteLocationProps = {
    onChange: (address?: Address) => void
    onBlur: (e: FocusEvent<HTMLInputElement, Element>) => void
    error?: string
    name: string
    defaultValue?: Address
}

const addressKeys: {[key: string]: {type: string[]; name: string}} = {
    city: {type: ['locality', 'administrative_area_level_3', 'administrative_area_level_2'], name: 'long_name'},
    zip_code: {type: ['postal_code'], name: 'long_name'},
    country_name: {type: ['country'], name: 'long_name'},
    country_code: {type: ['country'], name: 'short_name'},
    state_code: {type: ['administrative_area_level_1'], name: 'short_name'}
}
const extractAddressComponents = (place: google.maps.places.PlaceResult) => {
    const addressObject: Address & {[key: string]: string} = {
        country_name: '',
        city: '',
        zip_code: '',
        country_code: '',
        state_code: ''
    }

    if (place) {
        addressObject.raw_address = place.formatted_address
        addressObject.address = place.formatted_address

        const foundComponents: {[key: string]: boolean} = {
            city: false,
            zip_code: false,
            country_name: false,
            country_code: false,
            state_code: false
        }

        place.address_components?.forEach(component => {
            Object.keys(addressKeys).forEach(key => {
                if (!foundComponents[key] && addressKeys[key].type.find(type => component.types.includes(type))) {
                    const name = addressKeys[key].name
                    addressObject[key] = name === 'short_name' ? component.short_name : component.long_name
                    foundComponents[key] = true
                }
            })
        })
    }

    return addressObject
}
const AutoCompleteAddress: FC<AutoCompleteLocationProps> = ({onChange, onBlur, defaultValue, error, name}) => {
    const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(null)

    const handlePlaceChanged = () => {
        const address = place ? extractAddressComponents(place) : undefined
        onChange(address)
    }

    useEffect(() => {
        handlePlaceChanged()
    }, [place])

    return (
        <APIProvider apiKey={envVars.VITE_APP_GOOGLE_MAP_API_KEY || ''} language="en">
            <PlaceAutocomplete
                onPlaceSelect={setPlace}
                onBlur={onBlur}
                error={error}
                name={name}
                defaultValue={defaultValue}
            />
        </APIProvider>
    )
}
export default AutoCompleteAddress
