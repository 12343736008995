import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        height: 100%;
        padding: ${spacing}px ${spacing * 3}px ${spacing * 3}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 2}px;
        overflow-y: scroll;

        & button {
            width: auto;
        }

        ${mediaQueries.m} {
            width: 100%;
            gap: ${spacing * 3}px;
            min-width: 500px;
        }
    `}
`

export const StyledDescription = styled.p`
    ${({theme: {mediaQueries, palette, typography}}) => css`
        text-align: center;
        color: ${palette.neutral.black};
        white-space: pre-line;

        ${mediaQueries.m} {
            ${typography.textMd}
        }
    `}
`
