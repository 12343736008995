import styled, {css} from 'styled-components'

export const HasPaymentMethodRoot = styled.div`
    ${() => css``}
`

export const StyledAddOtherMethod = styled.div`
    border-radius: 18px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
    margin-top: 13px;
    height: 100%;
    min-height: 260px;

    & iframe {
        border-radius: 18px;
        border: none;
    }
`

export const StyledUpdateActiveCardButton = styled.div`
    ${() => css`
        & button {
            border-radius: 8px;
        }
    `}
`

export const StyledButtonContent = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${spacing}px;
    `}
`
