import {AUTH_API} from '@/features/authentication/services/auth.http.ts'
import {
    ROUTE_HOME,
    ROUTE_HOST_MY_PROFILE,
    ROUTE_LOGIN_GUEST,
    ROUTE_LOGIN_HOST,
    ROUTE_MESSAGE_CENTER
} from '@/utilities/constants/routeNames'
import {LocalStorageManager} from '@/utilities/localStorage'
import {SessionStorageManager} from '@/utilities/sessionStorage'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {useMe} from './useMe'
import {KNOWN_COOKIES, UserRoleE} from '@/types'
import {useResetStore} from '@/hooks/useResetStore'
import moengage from '@moengage/web-sdk'
import Cookies from 'js-cookie'

export const useLogout = (onSuccess?: () => void) => {
    const queryClient = useQueryClient()
    const {data} = useMe()
    const navigate = useNavigate()
    const resetStore = useResetStore()

    return useMutation({
        mutationFn: AUTH_API.logout,
        onSuccess: () => {
            const userType = data?.type
            onSuccess?.()
            queryClient.clear()
            LocalStorageManager.loginUser.remove()
            SessionStorageManager.checkIsFirstLogin.remove()
            SessionStorageManager.seenAddHomeScreenBanner.remove()
            LocalStorageManager.showSloganAnimation.remove(ROUTE_HOST_MY_PROFILE)
            LocalStorageManager.showSloganAnimation.remove(ROUTE_HOME)
            LocalStorageManager.showSloganAnimation.remove(ROUTE_MESSAGE_CENTER)
            moengage.destroy_session()
            navigate(userType === UserRoleE.Guest ? ROUTE_LOGIN_GUEST : ROUTE_LOGIN_HOST)
            resetStore()
            Cookies.remove(KNOWN_COOKIES.authCookie, {
                domain: KNOWN_COOKIES.mainDomain
            })
        }
    })
}
