import {PubnubStatus} from 'pubnub'
import {Channel, ChannelID, ChannelsList, Conversation, PubnubChatMessage, StoreChannel} from '@/features/chat/types.ts'
import {Normalized, RequiredPartial, UserRoleE} from '@/types.ts'
import {generateChatChannelName} from '@utilities/helpers'
import {Lens} from '@dhmk/zustand-lens'

// "pk" must exist in target Type
type NormalizedConversations = Normalized<ChannelID, 'pk', RequiredPartial<Conversation, 'pk'>>
type NormalizedChannels = Normalized<ChannelID, 'pk', StoreChannel>

type ChatState = {
    status: PubnubStatus | undefined
    conversations: NormalizedConversations // normalized Conversations, for Chat
    channels: NormalizedChannels // normalized channels, for Conversations in sidebar
    needReInitAfterNotification: boolean
}

type ConversationActions = {
    setMessages: (channelID: ChannelID, messages: PubnubChatMessage[]) => void
    addMessagesFromHistory: (channelID: ChannelID, messages: PubnubChatMessage[]) => void
    addMessage: (channelID: ChannelID, message: PubnubChatMessage) => void
    setLastMessage: (channelID: ChannelID, message: PubnubChatMessage) => void
    setLastMessages: (channels: {[key: ChannelID]: PubnubChatMessage[]}) => void
    increaseUnreadCounter: (channelID: ChannelID) => void
    setUnreadCounter: (channelID: ChannelID, count: number) => void
    setPaidMessages: (channelID: ChannelID, paidMessages: PubnubChatMessage[]) => void
    removeMessage: (channelID: ChannelID, timetoken: string) => void
}
type ChannelActions = {
    setChannels: (channels: ChannelsList, user: {id: number; role: UserRoleE}) => void
    setChannelDetails: (channelID: ChannelID, channelData: Channel) => void
    setNeedReInitAfterNotification: (neededReInit: boolean) => void
    reset: () => void
}

type ChatActions = ConversationActions & ChannelActions
export type ChatSlice = ChatState & ChatActions

const initialState: ChatState = {
    channels: {},
    conversations: {},
    status: undefined,
    needReInitAfterNotification: false
}

const createChatSlice: Lens<ChatSlice> = set => ({
    ...initialState,
    setChannels: (channelsList: ChannelsList, user: {id: number; role: UserRoleE}) =>
        set(state => {
            const storeChannels: NormalizedChannels = {}
            Object.keys(channelsList).forEach((chStatus: string) => {
                const safeStatus = chStatus as 'active' | 'inactive'
                const channels = channelsList[safeStatus]
                channels.forEach(ch => {
                    const pk: ChannelID = generateChatChannelName(user.id, user.role, ch.id)
                    storeChannels[pk] = {...ch, ...state.channels[pk], pk, channel_status: chStatus}
                })
            })
            state.channels = storeChannels
        }),
    setMessages: (channelID: ChannelID, messages: PubnubChatMessage[]) => {
        //TODO: change morgi into micromorgiAmount when backend is ready
        const paidMessages = messages.filter(
            message => message?.message?.meta?.micromorgiAmount || message?.message?.meta?.morgi
        )
        const paidMessagesMap = {} as {[key: string]: PubnubChatMessage}
        paidMessages.forEach(message => {
            paidMessagesMap[message.timetoken] = message
        })
        console.log("store",paidMessagesMap, paidMessages)
        return set(state => ({
            ...state,
            conversations: {
                ...state.conversations,
                [channelID]: {
                    ...state.conversations[channelID],
                    pk: channelID,
                    messages: messages,
                    paidMessages: state.conversations[channelID].paidMessages || paidMessagesMap,
                    unreadCounter: 0
                }
            }
        }))
    },
    removeMessage: (channelID: ChannelID, timetoken: string) =>
        set(state => ({
            conversations: {
                ...state.conversations,
                [channelID]: {
                    ...state.conversations[channelID],
                    messages: state.conversations[channelID].messages?.map(message =>
                        message.timetoken === timetoken
                            ? {
                                  ...message,
                                  actions: {
                                      deleted: {'.': [{uuid: message.uuid, actionTimetoken: Date.now()}]}
                                  }
                              }
                            : message
                    )
                }
            }
        })),
    addMessagesFromHistory: (channelID: ChannelID, messages: PubnubChatMessage[]) =>
        set(state => {
            const oldMessages = state.conversations[channelID]?.messages
            const mergedMessages = oldMessages?.length ? [...messages, ...oldMessages] : messages

            //TODO: change morgi into micromorgiAmount when backend is ready
            const paidMessages = messages.filter(
                message => message?.message?.meta?.micromorgiAmount || message?.message?.meta?.morgi
            )
            const paidMessagesMap = {} as {[key: string]: PubnubChatMessage}
            paidMessages.forEach(message => {
                paidMessagesMap[message.timetoken] = message
            })
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [channelID]: {
                        ...state.conversations[channelID],
                        pk: channelID,
                        messages: mergedMessages,
                        paidMessages: {...state.conversations[channelID].paidMessages, ...paidMessagesMap}
                    }
                }
            }
        }),
    addMessage: (channelID: ChannelID, message: PubnubChatMessage) =>
        set(state => {
            const oldMessages = state.conversations[channelID]?.messages
            const mergedMessages = oldMessages?.length ? [...oldMessages, message] : [message]
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [channelID]: {
                        ...state.conversations[channelID],
                        pk: channelID,
                        messages: mergedMessages
                    }
                },
                channels: {
                    ...state.channels,
                    [channelID]: {
                        ...state.channels[channelID],
                        pk: channelID
                    }
                }
            }
        }),
    increaseUnreadCounter: (channelID: ChannelID) =>
        set(state => {
            if (state.conversations[channelID]) {
                const unreadCounter = state.conversations[channelID]?.unreadCounter ?? 0
                state.conversations[channelID].unreadCounter = unreadCounter + 1
            } else {
                state.conversations[channelID] = {pk: channelID, unreadCounter: 1}
            }
        }),
    setUnreadCounter: (channelID: ChannelID, count: number) =>
        set(state => {
            if (state.conversations[channelID]) {
                state.conversations[channelID].unreadCounter = count
            } else {
                state.conversations[channelID] = {pk: channelID, unreadCounter: count}
            }
        }),
    setLastMessage: (channelID: ChannelID, message: PubnubChatMessage) =>
        set(state => {
            state.conversations[channelID].lastMessage = message
        }),
    setLastMessages: (channels: {[key: ChannelID]: PubnubChatMessage[]}) =>
        set(state => {
            //const updatedConversations: { [key: ChannelID]: Partial<Conversations> } = {...state.conversations}
            const updatedConversations: NormalizedConversations = {...state.conversations}
            // { 1111: [{..message...}], 2222: [{..message...}]
            Object.keys(channels).forEach(channelId => {
                const channelID = channelId as ChannelID
                const channel = channels[channelID]
                const channelLastMessage = channel[0]
                updatedConversations[channelID] = {
                    ...updatedConversations[channelID],
                    pk: channelID,
                    lastMessage: channelLastMessage
                }
            })
            return {
                ...state,
                conversations: updatedConversations
            }
        }),
    setChannelDetails: (channelID: ChannelID, channelData: Channel) =>
        set(state => {
            state.channels[channelID] = {
                ...state.channels[channelID],
                ...channelData
            }
        }),
    setPaidMessages: (channelID: ChannelID, paidMessages: PubnubChatMessage[]) =>
        set(state => ({
            ...state,
            conversations: {
                ...state.conversations,
                [channelID]: {
                    ...state.conversations[channelID],
                    paidMessages
                }
            }
        })),
    setNeedReInitAfterNotification: (neededReInit: boolean) =>
        set(state => ({
            ...state,
            needReInitAfterNotification: neededReInit
        })),
    reset: () => {
        set(initialState)
    }
})

export default createChatSlice
