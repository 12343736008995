import styled, {css} from 'styled-components'

export const StyledFormRoot = styled.form`
    ${({theme: {spacing}}) => css`
        display: flex;
        position: relative;
        flex-flow: column;
        gap: ${spacing}px;
        max-width: 450px;
        width: 100%;
        justify-content: space-between;
        margin-bottom: ${spacing * 1.5}px;

        & button {
            margin-top: ${spacing * 3}px;
            padding: ${spacing * 2}px 0px;
        }
    `}
`

export const StyledDescription = styled.p`
    ${({theme: {mediaQueries, palette, spacing, typography}}) => css`
        color: ${palette.primary[700]};
        white-space: pre-line;
        margin-top: ${spacing * 3}px;

        ${mediaQueries.m} {
            ${typography.textMd}
        }
    `}
`
