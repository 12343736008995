import {ChannelID, StoreChannel} from '@/features/chat/types'
import {useRootStore} from '@/store'
import {selectChatStore, selectChatChannels} from '@/store/selectors'
import {useEffect, useRef, useState} from 'react'
import {dateToPubnubTimetoken, getCorrectUserId} from '@/utilities/helpers'
import Pubnub, {ListenerParameters} from 'pubnub'
import {User} from '@/features/authentication/types.ts'
import {MessageTypeE} from '@/utilities/constants/chat'

type MessageCountProps = {
    pubnubInstance?: Pubnub
    user?: User
}

const useUnreadMessagesCount = ({pubnubInstance, user}: MessageCountProps) => {
    const channels = useRootStore(selectChatChannels)
    const {increaseUnreadCounter, setUnreadCounter} = useRootStore(selectChatStore)
    const correctUserId = user ? getCorrectUserId(user) : undefined
    const [isMessageCountInitialized, setIsMessageCountInitialized] = useState(false)

    useEffect(() => {
        getMessageCounts()
    }, [pubnubInstance, Object.keys(channels).length])

    // Here we get the last read messages counters for all the chats
    const getMessageCounts = async () => {
        try {
            if (Object.keys(channels).length && pubnubInstance && !isMessageCountInitialized) {
                const channelsNames: string[] = []
                const channelsLastReadTimetokens: string[] = []
                Object.keys(channels).forEach(key => {
                    const channelName = key as ChannelID
                    const channelItem: StoreChannel = channels[channelName]
                    if (channelItem.last_reads_timetokens?.length || channelItem.created_at) {
                        channelsNames.push(channelName)
                        const lastReadTimetoken = channelItem.last_reads_timetokens?.length
                            ? channelItem.last_reads_timetokens[0].timetoken
                            : String(dateToPubnubTimetoken(channelItem?.created_at ?? ''))
                        channelsLastReadTimetokens.push(lastReadTimetoken)
                    }
                })
                const counters = await pubnubInstance.messageCounts({
                    channels: channelsNames,
                    channelTimetokens: channelsLastReadTimetokens
                })
                Object.keys(counters.channels).forEach(key => {
                    const channelName = key as ChannelID
                    setUnreadCounter(channelName, counters.channels[key])
                })
                setIsMessageCountInitialized(true)
            }
        } catch (error) {
            console.log('Error in getting message counts', error)
        }
    }

    // Subscribe to all channels to get notified about new messages
    const listenerRef = useRef<ListenerParameters | null>(null)
    useEffect(() => {
        if (pubnubInstance) {
            listenerRef.current = {
                message: receivedMessage => {
                    const targetChannel = receivedMessage.channel as ChannelID
                    if (
                        String(receivedMessage.publisher) !== String(correctUserId) &&
                        receivedMessage.message.type !== MessageTypeE.DELETED
                    ) {
                        increaseUnreadCounter(targetChannel)
                    }
                }
            }
            pubnubInstance.addListener(listenerRef.current)
        }
        return () => {
            if (listenerRef.current) {
                pubnubInstance?.removeListener(listenerRef.current)
            }
        }
    }, [pubnubInstance, user])
}

export default useUnreadMessagesCount
